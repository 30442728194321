import { TextField } from "@mui/material";
import styled from "@emotion/styled";

const CustomTextField = styled((props: any) => <TextField {...props} />)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    border: '1px solid #ccc', // Add a border
    borderRadius: '8px', // Optional: Rounded corners
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#555', // Border color on hover
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main, // Border color when focused
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #ccc', // Ensure border is applied
  },
  '& .MuiFormHelperText-root': {
    marginTop: '4px', // Adjust spacing for helper text
    fontSize: '0.750rem',
  },
}));


export default CustomTextField;