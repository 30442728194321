import axiosServices from "src/utils/axios";
import axios from "axios";
import { format, parseISO } from "date-fns";
import { notifyMessage } from "src/utils/toastNotify";
import { setReset } from "src/store/slices/rangeUsagesClub/RangeUsages";
import { deleteLocalFormData } from "src/services/localStorage/localServices";


export const getClubDetailById = async (id: number) => {
    try {
        const response = await axiosServices.get(`/tenants/get-tenant/${id}`);
        if (response.status === 200) {
            const ClubDetail = response.data;

            return ClubDetail;
        } else {
            console.error("Failed to fetch ClubDetail:", response);
            throw new Error("Failed to fetch ClubDetail");
        }
    } catch (error) {
        console.error("Failed to fetch ClubDetail:", error);
        throw new Error("Failed to fetch ClubDetail");
    }
};


export const getClubId = async (club_name: string) => {
    try {
        const response = await axiosServices.get(`/range/${club_name}`);
        if (response.status === 200) {
            const ClubDetail = response.data;

            console.log("getClubId", ClubDetail);

            return ClubDetail;
        } else {
            console.error("Failed to fetch ClubDetail:", response);
            throw new Error("Failed to fetch ClubDetail");
        }
    } catch (error) {
        console.error("Failed to fetch ClubDetail:", error);
        throw new Error("Failed to fetch ClubDetail");
    }
};



// export const RangeRegister = async (data: any) => {
//     // console.log(data, "in api call")

//     try {
//         const formData = new FormData();

//         Object.entries(data).forEach(([key, value]) => {
//             if (value instanceof File) {
//                 formData.append(key, value);
//             } else if (typeof value === "string" || typeof value === "number" || typeof value === "boolean") {
//                 formData.append(key, value.toString());
//             } else if (key === "playingEvents") {
//                 formData.append(key, JSON.stringify(value));
//             }
//         });

//         // Object.entries(data.slideTwo).forEach(([key, value]) => {
//         //     if (value instanceof File) {
//         //         formData.append(key, value);
//         //     } else if (typeof value === "string" || typeof value === "number" || typeof value === "boolean") {
//         //         formData.append(key, value.toString());
//         //     }
//         // });


//         const response = await axiosServices.post("/range/register", formData);

//         // console.log(response, "clubRegister");

//         if (response.status === 200) {

//             const result = response.data;

//             return result;
//         } else {
//             console.error("Unexpected response:", response);
//             throw new Error("Failed to ClubRegister");
//         }
//     } catch (error) {
//         console.error("Failed to ClubRegister", error);
//         throw new Error("Failed to ClubRegister");
//     }
// };







// export const RangeRegister = async (data: any) => {
//     // console.log(data, "in api call");

//     try {
//         const formData = new FormData();

//         const appendFormData = (sectionData: any, sectionName: any) => {
//             Object.entries(sectionData).forEach(([key, value]) => {
//                 const formKey = `${sectionName}[${key}]`;
//                 if (value instanceof File) {
//                     formData.append(formKey, value);
//                 } else if (typeof value === "string" || typeof value === "number" || typeof value === "boolean") {
//                     formData.append(formKey, value.toString());
//                 } else if (Array.isArray(value) || typeof value === "object") {
//                     formData.append(formKey, JSON.stringify(value));
//                 }
//             });
//         };

//         // Append each section to the FormData
//         appendFormData(data.confidentialDetails, "confidentialDetails");
//         appendFormData(data.educationalDetails, "educationalDetails");
//         appendFormData(data.gaurdianDetails, "gaurdianDetails");

//         const response = await axiosServices.post("/range/register", formData);

//         // console.log(response, "RangeRegister");

//         if (response.status === 200) {
//             const result = response.data;

//             return result;

//         } else {
//             console.error("Unexpected response:", response);
//             throw new Error("Failed to RangeRegister");
//         }
//     } catch (error) {
//         console.error("Failed to RangeRegister", error);
//         throw new Error("Failed to RangeRegister");
//     }
// };



export const RangeRegister = async (
    data: {
        confidentialDetails: any;
        educationalDetails: any;
        gaurdianDetails: any[];
    },
    dispatch: any // Receive dispatch as an argument
) => {
    // console.log(data, "in api call");

    try {
        const formData = new FormData();

        const appendFormData = (sectionData: any, sectionName: string) => {
            if (!sectionData) {
                return; // Handle empty sections gracefully
            }

            Object.entries(sectionData).forEach(([key, value]) => {
                const formKey = `${sectionName}[${key}]`;

                if (value instanceof File) {
                    formData.append(formKey, value);
                } else if (typeof value === "string" || typeof value === "number" || typeof value === "boolean") {
                    formData.append(formKey, value.toString());
                } else if (Array.isArray(value)) {
                    value.forEach((item, index) => {
                        appendFormData(item, `${formKey}[${index}]`); // Recursively handle arrays
                    });
                } else if (typeof value === "object") {
                    appendFormData(value, formKey); // Recursively handle nested objects
                }
            });
        };

        // Append each section to the FormData (with optional validation)
        if (data.confidentialDetails) {
            appendFormData(data.confidentialDetails, "confidentialDetails");
        }
        if (data.educationalDetails) {
            appendFormData(data.educationalDetails, "educationalDetails");
        }
        if (data.gaurdianDetails) {
            appendFormData(data.gaurdianDetails, "gaurdianDetails");
        } else {
            console.warn("gaurdianDetails section is missing in the data"); // Log a warning
        }

        const response = await axiosServices.post(`range/register`, formData);

        // console.log(response, "RangeRegister");

        if (response.status === 200) {
            const result = response.data;
            notifyMessage.success("Range registered successfully");
            // here reset the redux state
            dispatch(setReset());
            deleteLocalFormData("range");

            return result;
        } else {
            console.error("Unexpected response:", response);
            throw new Error("Failed to RangeRegister");
        }
    } catch (error) {
        console.error("Failed to RangeRegister", error);
        throw new Error("Failed to RangeRegister");
    }
};




export const RangeUpdation = async (
    data: {
        confidentialDetails: any;
        educationalDetails: any;
        gaurdianDetails: any[];
    },
    dispatch: any // Receive dispatch as an argument
) => {
    // console.log(data, "in api call");

    try {
        const formData = new FormData();

        const appendFormData = (sectionData: any, sectionName: string) => {
            if (!sectionData) {
                return; // Handle empty sections gracefully
            }

            Object.entries(sectionData).forEach(([key, value]) => {
                const formKey = `${sectionName}[${key}]`;

                if (value instanceof File) {
                    formData.append(formKey, value);
                } else if (typeof value === "string" || typeof value === "number" || typeof value === "boolean") {
                    formData.append(formKey, value.toString());
                } else if (Array.isArray(value)) {
                    value.forEach((item, index) => {
                        appendFormData(item, `${formKey}[${index}]`); // Recursively handle arrays
                    });
                } else if (typeof value === "object") {
                    appendFormData(value, formKey); // Recursively handle nested objects
                }
            });
        };

        // Append each section to the FormData (with optional validation)
        if (data.confidentialDetails) {
            appendFormData(data.confidentialDetails, "confidentialDetails");
        }
        if (data.educationalDetails) {
            appendFormData(data.educationalDetails, "educationalDetails");
        }
        if (data.gaurdianDetails) {
            appendFormData(data.gaurdianDetails, "gaurdianDetails");
        } else {
            console.warn("gaurdianDetails section is missing in the data"); // Log a warning
        }

        const response = await axiosServices.put(`range/updaterangeregsiterdata`, formData);

        // console.log(response, "RangeUpdation");

        if (response.status === 200) {
            const result = response.data;
            notifyMessage.success("Range registration updated successfully");
            dispatch(setReset());
            deleteLocalFormData("range");

            return result;
        } else {
            console.error("Unexpected response:", response);
            throw new Error("Failed to RangeRegister");
        }
    } catch (error) {
        console.error("Failed to RangeRegister", error);
        throw new Error("Failed to RangeRegister");
    }
};


//for selection

export const getAllRanges = async () => {
    try {
        const response = await axiosServices.get(`/range/viewrange`);
        if (response.status === 200) {
            const ClubDetail = response.data.data

            return ClubDetail;
        } else {
            console.error("Failed to fetch ClubDetail:", response);
            throw new Error("Failed to fetch ClubDetail");
        }
    } catch (error) {
        console.error("Failed to fetch ClubDetail:", error);
        throw new Error("Failed to fetch ClubDetail");
    }
};


//Range selection

export const SaveRangeSelection = async (data: any) => {
    try {
        // console.log(data, "Want to Sentdata");
        const response = await axiosServices.post("/range/saverangedetails", data);
        // console.log(response, "Sentdata");

        if (response.status === 200) {
            const result = response.data;
            //notifyMessage.success("Selected Range Saved successfully!");

            return result;
        } else {
            console.error("Unexpected response:", response);
        }
    } catch (error: any) {
        notifyMessage.error(error.response?.data?.message || error.message || "An error occurred while saving the range selection.");
        console.error("Failed to SaveRange", error);
        // throw new Error("Failed to SaveRange");

    }
};


export const CheckRangePurchased = async (data: any) => {
    try {
        // console.log(data, "Want to check purchased");
        const response = await axiosServices.post(`/range/checkrangealreadyrented`, data);
        // ${process.env.REACT_APP_BASE_URL}
        // console.log(response, "Sentdata");

        if (response.status === 200) {
            const result = response.data;

            return response;
        } else {
            console.error("Unexpected response:", response);
        }
    } catch (error: any) {
        // notifyMessage.error(error.response?.data?.message || error.message || "An error occurred while saving the range selection.");
        console.error("Failed to SaveRange", error);
        // throw new Error("Failed to SaveRange");

    }
};



//purchasded ranges
export const getAllRentedRanges = async () => {
    try {
        const response = await axiosServices.get(`/range/viewrentedranges`);
        if (response.status === 200) {
            const ClubDetail = response.data.data

            return ClubDetail;
        } else {
            console.error("Failed to fetch RentedRangesl:", response);
            throw new Error("Failed to fetch RentedRanges");
        }
    } catch (error) {
        console.error("Failed to fetch RentedRanges:", error);
        throw new Error("Failed to fetch RentedRanges");
    }
};

export const getAllRentedAirTarget = async () => {
    try {
        const response = await axiosServices.get(`${process.env.REACT_APP_BASE_URL}/range/viewpurchasedetails`);
        if (response.status === 200) {
            const purchasedairtarget = response.data.data

            return purchasedairtarget;
        } else {
            console.error("Failed to fetch purchasedairtarget:", response);
            throw new Error("Failed to fetch purchasedairtarget");
        }
    } catch (error) {
        console.error("Failed to fetch purchasedairtarget:", error);
        throw new Error("Failed to fetch purchasedairtarget");
    }
};

export const getAllRentedAirPallets = async () => {
    try {
        const response = await axiosServices.get(`${process.env.REACT_APP_BASE_URL}/range/viewpurchasedpallets`);
        if (response.status === 200) {
            const purchasedairpallets = response.data.data

            return purchasedairpallets;
        } else {
            console.error("Failed to fetch purchasedairpallets:", response);
            throw new Error("Failed to fetch purchasedairpallets");
        }
    } catch (error) {
        console.error("Failed to fetch purchasedairpallets:", error);
        throw new Error("Failed to fetch purchasedairpallets");
    }
};

export const SaveAirPelletsData = async (data: any) => {
    try {
        const response = await axiosServices.post("/range/savepurchasedpallets", data);

        if (response.status === 200) {
            const result = response.data;

            return result;
        } else {
            console.error("Unexpected response:", response);
            throw new Error("Failed to Purchase Air Pellets!");
        }
    } catch (error) {
        console.error("Failed to Purchase Air Pellets!", error);
        throw new Error("Failed to Purchase Air Pellets!");
    }
};


export const SaveAirTargetData = async (data: any) => {
    try {
        const response = await axiosServices.post(`/range/savepurchasedetails`, data);

        if (response.status === 200) {
            const result = response.data;

            return result;
        } else {
            console.error("Unexpected response:", response);
            throw new Error("Failed to Purchase Air Targets!");
        }
    } catch (error) {
        console.error("Failed to Purchase Air Targets!", error);
        throw new Error("Failed to Purchase Air Targets!");
    }
};


export const UploadOnCloud = async (data: File) => {
    try {
        // console.log(data, "check file");


        const formData = new FormData();
        formData.append('image', data);

        const response = await axiosServices.post(`${process.env.REACT_APP_BASE_URL}/range/uploadfile`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        if (response.status === 200) {
            const result = response.data.data;
            // console.log(result, "file link");

            return result;
        } else {
            console.error("Unexpected response:", response);
            throw new Error("Failed to upload!");
        }
    } catch (error) {
        console.error("Failed to upload file!", error);
        throw new Error("Failed to upload!");
    }
};

export const SaveRentalFireArmData = async (data: any) => {
    // console.log("SaveRentalFireArmData Functon Data: ", data);
    try {
        const response = await axiosServices.post(`${process.env.REACT_APP_BASE_URL}/range/saveandupdateweaponutilizationdetails`, data); // Data has to be sent properly as per the backend

        if (response.status === 200) {
            const result = response.data;

            return result;
        } else {
            console.error("Unexpected response:", response);
            throw new Error("Failed to Make payment for firearm!");
        }
    } catch (error) {
        console.error("Failed to Purchase firearm!", error);
        throw new Error("Failed to Make payment for firearm!");
    }
};

export const SaveRentalLockerRoomData = async (data: any, id: any) => {

    // console.log("SaveRentalLockerRoomData Functon Data: ", data);
    try {
        const response = await axiosServices.put(`${process.env.REACT_APP_BASE_URL}/range/updatelockerdetails/${id}`, data); // Api have to be changed according to locker room

        if (response.status === 200) {
            const result = response.data;

            return result;
        } else {
            console.error("Unexpected response:", response);
            throw new Error("Failed to Make payment for locker room!");
        }
    } catch (error) {
        console.error("Failed to Purchase locker room!", error);
        throw new Error("Failed to Make payment for locker room!");
    }
};