import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, MenuItem, Typography, FormControl, FormHelperText, Stack, TextField, Tooltip, IconButton, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import CustomTextField from 'src/utils/theme-elements/CustomTextField';
import CustomSelect from 'src/utils/theme-elements/CustomSelect'; // Ensure you import CustomSelect if it's a custom component
import DataTable from 'src/components/table/TableComponent';
import { notifyMessage } from 'src/utils/toastNotify';
import BackLink from 'src/components/back-link';
import APP_ROUTES from 'src/routes/routePaths';
import BlankCard from "src/components/shared/BlankCard";
import PageContainer from "src/components/page-container/PageContainer";
import { getAllRanges } from "src/views/clubs/api-call/rangeUse";
import { useNavigate, useParams } from "react-router";
import CustomFormLabel from "src/utils/theme-elements/CustomFormLabel";
import { RequiredStar } from "src/components/required-star";
import { generateErrorInitialState } from "src/utils/FormValidate";
import AlertBox from "src/layouts/full/shared/AlertBox/AlertBox";
import { IconTrash } from "@tabler/icons";

import {
    createRangeDuration,
    deleteRangeDurById,
} from "../../api-call/adminRangeUse";
import { IconArrowNarrowLeft, IconPencil } from '@tabler/icons-react';
import { PreventWheelChange } from 'src/utils/PreventWheelChange';
import { GridColDef } from '@mui/x-data-grid';
import axiosServices from 'src/utils/axios';

const AddDurations = () => {
    const { id } = useParams<{ id: string }>();
    const [selectedRangeId, setSelectedRangeId] = useState<string | undefined>(undefined);
    const [rangePeriodType, setRangePeriodType] = useState<string>("");
    const initialDetailState = { range_name: "" };
    const [detail, setDetail] = useState(initialDetailState);
    const [ranges, setRanges] = useState<any[]>([]);
    const [selectedRange, setSelectedRange] = useState("");
    const [rangePeriodDurations, setRangePeriodDurations] = useState<any[]>([]);
    const [selectedId, setSelectedId] = useState("");
    const [rangeNames, setRangeName] = useState<string[]>([]);
    const [inputValues, setInputValues] = useState<number[]>([]);
    const [selectedDurId, setSelectedDurId] = useState("");
    const [open, setOpen] = useState(false);
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [openUpdate, setOpenUpdate] = useState(false);
    const [rangeUpdateDetails, setRangeUpdateDetails] = useState({
        id: '',
        rangeAmount: '',
        rangeName: '',
        rangePeriodDur: '',
        rangePeriodType: '',
        serialNo: '',
    });

    const navigate = useNavigate();

    interface FormData {
        range_id: string;
        range_period_type: string;
        range_period_duration: string;
        range_amount: string;
    }

    interface RangeDurationRow {
        id: string;
        serialNo: number;
        rangeName: string;
        rangePeriodDur: string;
        rangePeriodType: string;
        rangeAmount: number;
        // Add more properties as needed
    }

    const initialFormdata = {
        range_id: "",
        // range_name: "",
        range_period_type: "",
        range_period_duration: "",
        range_amount: "",
    };
    const [formData, setFormData] = React.useState<FormData>(initialFormdata);

    const errorInitialState: any = generateErrorInitialState(formData);
    const [error, setError] = useState(errorInitialState);

    const wholeError = () => {
        const newErrors: Partial<FormData> = {};
        if (!formData.range_id) {
            newErrors.range_id = ' ';
        }
        if (!formData.range_period_type) {
            newErrors.range_period_type = 'Range period type is required';
        }
        if (!formData.range_period_duration) {
            newErrors.range_period_duration = 'Duration is required';
        }
        if (!formData.range_amount) {
            newErrors.range_amount = 'Range amount is required';
        } else if (Number(formData.range_amount) < 1) {
            newErrors.range_amount = 'Should be greater than 0';
        }
        setError(newErrors);
    };

    const handleRangeIdBlur = (value: string) => {
        const newErrors: Partial<FormData> = { ...error };
        if (!value) {
            newErrors.range_id = 'Range ID is required';
        } else {
            delete newErrors.range_id;
        }
        setError(newErrors);
    };

    const handleRangePeriodTypeBlur = (value: string) => {
        const newErrors: Partial<FormData> = { ...error };
        if (!value) {
            newErrors.range_period_type = 'Range period type is required';
        } else {
            delete newErrors.range_period_type;
        }
        setError(newErrors);
    };

    const handleRangePeriodDurationBlur = (value: string) => {
        const newErrors: Partial<FormData> = { ...error };
        if (!value) {
            newErrors.range_period_duration = 'Duration is required';
        } else {
            delete newErrors.range_period_duration;
        }
        setError(newErrors);
    };

    const handleRangeAmountBlur = (value: string) => {
        const newErrors: Partial<FormData> = { ...error };
        if (!value) {
            newErrors.range_amount = 'Range amount is required';
        } else {
            delete newErrors.range_amount;
        }
        setError(newErrors);
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        switch (name) {
            case 'range_id':
                handleRangeIdBlur(value);
                break;
            case 'range_period_type':
                handleRangePeriodTypeBlur(value);
                break;
            case 'range_period_duration':
                handleRangePeriodDurationBlur(value);
                break;
            case 'range_amount':
                handleRangeAmountBlur(value);
                break;
            default:
                break;
        }
    };

    const handleChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name as string]: name === "range_amount" ? Number(value) : value,
        });

        if (name === "range_period_type") {
            setRangePeriodType(value);
            setFormData((prevData: any) => ({
                ...prevData,
                range_period_type: value,
                range_period_duration: '',
            }));
        }

        if (name === "range_name") {
            setDetail({ ...detail, [name]: value });
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const check = Object.values(formData).every((e: any) => e !== "");
        if (!check) {
            // notifyMessage.error("Check all the required fields");
            wholeError();
        }
        // if (Number(formData.range_amount) < 1) {
        //     setError((prevErrors: any) => ({
        //         ...prevErrors,
        //         range_amount: 'Should be greater than 0',
        //     }));

        //     return; // Stop submission if range_amount is less than 1
        // } 
        else {
            const updatedFormData = {
                range_id: Number(formData.range_id),
                range_period_type: formData.range_period_type,
                range_period_duration: formData.range_period_duration,
                range_amount: formData.range_amount,
            };
            const saveDuration = await createRangeDuration(updatedFormData);
            // console.log("saveDuration", saveDuration);
            if (saveDuration.success) {
                notifyMessage.success("Range with duration created Successfully");
            }
            const response = await fetchRange();
            setRanges(response);
            setRangePeriodDurations(rangePeriodDurations);
            setFormData(initialFormdata);
            fetchData();
        }
    };

    const handleOpenAlert = (id: any) => {
        // console.log("ID", id);
        setSelectedDurId(id);
        setAlertOpen(true);
    };

    const RangeDurationRow = (rangePeriodDurations ?? []).map((ran, index) => ({
        id: ran.id,
        serialNo: index + 1,
        rangeName: ran.rangeName,
        rangePeriodDur: ran.duration,
        rangePeriodType: ran.type,
        rangeAmount: ran.price,
    }));

    const RangeDurationColumn: GridColDef[] = [
        { field: 'serialNo', headerName: 'ID', flex: 0.5 },
        {
            field: 'rangeName', headerName: 'Range Name', flex: 1,
            renderCell: (params: any) => (<div style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.2' }}> {params.value} </div>)
        },
        {
            field: 'rangePeriodDur', headerName: 'Period Duration', flex: 1,
            renderCell: (params: any) => (<div style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.2' }}> {params.value} </div>)
        },
        {
            field: 'rangePeriodType', headerName: 'Period Type', flex: 1,
            renderCell: (params: any) => (<div style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.2' }}> {params.value} </div>)
        },
        {
            field: 'rangeAmount', headerName: 'Amount', flex: 1,
            renderCell: (params: any) => (<div style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.2' }}> {params.value + '₹'} </div>)
        },
        {
            field: 'actions',
            headerName: 'Action',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params: { row: RangeDurationRow }) => (
                <>
                    <Tooltip title="Delete">
                        <IconButton
                            sx={{ cursor: 'pointer' }}
                            onClick={() => handleOpenEdit(params.row)}
                        >
                            <IconPencil size="22" stroke={1.4} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                        <IconButton
                            sx={{ cursor: 'pointer' }}
                            onClick={() => handleOpenAlert(params.row.id)}
                        >
                            <IconTrash size="22" stroke={1.4} />
                        </IconButton>
                    </Tooltip>
                </>
            ),
        },
    ];


    const fetchRange = async (): Promise<Range[]> => {
        try {
            const response = await getAllRanges();
            const competitionsData: Range[] = response;

            return competitionsData;
        } catch (error) {
            console.error("Failed to fetch all ranges", error);
            throw error;
        }
    };

    const handleClose = () => {
        setRangePeriodDurations([]);
        setError(errorInitialState);
        setOpen(false);
    };

    const handleCloseDurAlert = () => {
        setAlertOpen(false);
    };

    const handleCloseAlert = () => {
        setAlertOpen(false);
    };

    const handleDurDelete = async () => {
        try {
            if (selectedDurId) {
                const response = await deleteRangeDurById(selectedDurId);
                if (response.success) {
                    notifyMessage.success("Range Duration deleted successfully");
                    fetchData();
                } else {
                    notifyMessage.error(response.message);
                }
            }
        } catch (error) {
            notifyMessage.error("Failed to delete Range Dur");
        } finally {
            handleCloseDurAlert();
        }
    };

    const fetchData = async () => {
        const range_id = id;
        setSelectedRangeId(range_id);
        try {
            if (selectedRangeId) {
                const response = await fetchRange();
                // console.log("rangePeriodDurations", response);
                setRanges(response);
                const selectedRange = response.filter((comp: any) => comp.range_id == selectedRangeId).map((comp: any) => comp.range_name)[0];
                setSelectedRange(selectedRange);

                setFormData((prevState: any) => ({
                    ...prevState,
                    range_id: selectedRangeId,
                    //range_name: selectedRange,
                }));

                const rangePeriodDurations = (response || [])
                    .filter((comp: any) => comp.range_id == selectedRangeId)
                    .flatMap((comp: any) =>
                        (comp.range_period_data || []).map((period: any) => ({
                            id: period.range_period_id,
                            duration: period.range_period_duration,
                            type: period.range_period_type,
                            price: period.range_amount,
                            rangeName: comp.range_name,
                        }))
                    );

                setRangePeriodDurations(rangePeriodDurations);


                const rangeName = response.map((comp: any) => comp.range_name);
                setRangeName(rangeName);
            }
        } catch (error) {
            console.error("Failed to fetch ranges", error);
        }
    };


    useEffect(() => {
        fetchData();
    }, [selectedId, selectedRangeId]);

    useEffect(() => {
        const newInputValues = rangePeriodType === "day" ? Array.from({ length: 31 }, (_, i) => i + 1) : Array.from({ length: 12 }, (_, i) => i + 1);
        setInputValues(newInputValues);
    }, [rangePeriodType]);

    const handelPropValue = () => {
        navigate(`${APP_ROUTES.ADMIN_CLUB_RANGE_SELECTION}`, { state: { propValue: '2' } });
    };

    const handleChangesUpdatePrice = (e: any) => {
        const { name, value } = e.target;

        setRangeUpdateDetails((prevState: any) => ({
            ...prevState,
            rangeAmount: value,
        }));
    };

    const handleOpenEdit = (data: any) => {
        // console.log("data", data);
        setRangeUpdateDetails({
            id: data.id,
            rangeAmount: data.rangeAmount,
            rangeName: data.rangeName,
            rangePeriodDur: data.rangePeriodDur,
            rangePeriodType: data.rangePeriodType,
            serialNo: data.serialNo,
        });
        setOpenUpdate(true);
    }

    const handleUpdateClose = () => {
        setError(errorInitialState);
        setRangeUpdateDetails({
            id: '',
            rangeAmount: '',
            rangeName: '',
            rangePeriodDur: '',
            rangePeriodType: '',
            serialNo: '',
        });
        setOpenUpdate(false);
    };

    const handleUpdate = async () => {
        // console.log("rangeUpdateDetails", rangeUpdateDetails);

        const data = {
            "range_period_duration": rangeUpdateDetails.rangePeriodDur,
            "range_period_type": rangeUpdateDetails.rangePeriodType,
            "range_amount": Number(rangeUpdateDetails.rangeAmount),
        };

        // console.log("Data", data);
        try {
            const response = await axiosServices.put(`range/editrangeperiod/${rangeUpdateDetails.id}`, data);

            // console.log("API Response", response.data);

            if (response.data.success) {
                notifyMessage.success(response.data.message);
                // console.log("Update successful", response.data.message);
                fetchData();
                // const response1 = await fetchRange();
                // setRanges(response1);
                // setRangePeriodDurations(rangePeriodDurations);
                setFormData(initialFormdata);
                handleUpdateClose();
            } else {
                console.error("Failed to update");
                // Handle failure case (e.g., show error message)
            }
        } catch (error) {
            console.error("Error updating range details:", error);
            // Handle error (e.g., show error notification)
        }
        handleUpdateClose();
    };

    return (
        <>
            {/* <BackLink title='Get Back To Range Selection' route={APP_ROUTES.ADMIN_CLUB_RANGE_SELECTION} /> */}
            <div
                style={{ display: "flex", alignItems: "center", color: "#000", gap: "10px", marginBottom: "8px", cursor: "pointer" }}
                onClick={handelPropValue}
            >
                <IconArrowNarrowLeft stroke={1.6} /> {"Get Back To Range Selection"}
            </div>
            <PageContainer>
                <BlankCard>
                    <Box sx={{ p: 4 }}>
                        <Stack sx={{ background: "#ECF2FF", borderRadius: "6px" }} direction="row" justifyContent="space-between" alignItems="center" p={2}>
                            <Typography variant="h5" gutterBottom>Add Range Duration</Typography>
                        </Stack><br />

                        <Grid container spacing={2} mb={2}>
                            <Grid item xs={12} lg={2.6} sx={{ mt: 1 }}>
                                <CustomFormLabel sx={{ mt: 0 }} htmlFor="range_name">
                                    Range Name <b style={{ color: "red" }}>*</b>
                                </CustomFormLabel>
                                <CustomTextField
                                    fullWidth
                                    InputProps={{ readOnly: true }}
                                    error={!!error.range_name}
                                    helperText={error.range_name}
                                    id="range_name"
                                    name="range_name"
                                    value={selectedRange}
                                    onChange={handleChanges}
                                />
                            </Grid>

                            <Grid item xs={12} lg={2.6} sx={{ mt: 0, marginLeft: 1 }}>
                                <CustomFormLabel sx={{ mt: 1 }} htmlFor="text-email">
                                    Range Period Type <RequiredStar />
                                </CustomFormLabel>
                                <FormControl fullWidth error={!!error.range_period_type}>
                                    <CustomSelect
                                        id="demo-simple-select"
                                        // labelId={`range_period_type-label`}
                                        name="range_period_type"
                                        value={formData.range_period_type}
                                        onChange={handleChanges}
                                        onBlur={handleBlur}
                                    >
                                        <MenuItem value="day">Day</MenuItem>
                                        <MenuItem value="month">Month</MenuItem>
                                    </CustomSelect>
                                    {error.range_period_type ? <FormHelperText>Range period is required</FormHelperText> : " "}
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} lg={2.6} sx={{ mt: 0, marginLeft: 1 }}>
                                <CustomFormLabel sx={{ mt: 1 }} htmlFor="text-email">
                                    Range Period Duration <RequiredStar />
                                </CustomFormLabel>
                                <FormControl fullWidth error={!!error.range_period_duration}>
                                    <CustomSelect
                                        id="demo-simple-select"
                                        name="range_period_duration"
                                        value={formData.range_period_duration}
                                        onChange={handleChanges}
                                        onBlur={handleBlur}
                                        displayEmpty
                                        inputProps={{ "aria-label": "Without label" }}
                                        fullWidth
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 200,
                                                },
                                            },
                                        }}
                                    >
                                        {rangePeriodType && rangePeriodType === "day" ? (
                                            inputValues.map((i: any) => (
                                                <MenuItem key={i} value={i}>
                                                    {i} {i == 1 ? 'Day' : 'Days'}
                                                </MenuItem>
                                            ))
                                        ) : (
                                            inputValues.map((i: any) => (
                                                <MenuItem key={i} value={i}>
                                                    {i} {i == 1 ? 'Month' : 'Months'}
                                                </MenuItem>
                                            ))
                                        )}
                                    </CustomSelect>
                                    {error.range_period_duration ? <FormHelperText>{error.range_period_duration}</FormHelperText> : " "}
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} lg={2.6} sx={{ mt: 1 }}>
                                <CustomFormLabel sx={{ mt: 0 }} htmlFor="range_amount">
                                    Range Amount <b style={{ color: "red" }}>*</b>
                                </CustomFormLabel>
                                <CustomTextField
                                    id="range_amount"
                                    fullWidth
                                    placeholder="00.0"
                                    name="range_amount"
                                    value={formData.range_amount}
                                    onChange={handleChanges}
                                    onBlur={handleBlur}
                                    onKeyPress={(e: any) => {
                                        // Allow only numbers and some specific keys like Backspace, Delete, Arrow keys
                                        const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];
                                        if (!allowedKeys.includes(e.key)) {
                                            e.preventDefault();
                                        }
                                    }}
                                    onWheel={PreventWheelChange}
                                    error={!!error.range_amount}
                                    helperText={error.range_amount || " "}
                                />
                            </Grid>

                            <Grid item xs={12} lg={1} sx={{ mt: 5 }}>
                                <Button onClick={handleSubmit} variant="contained" color="primary">
                                    Add
                                </Button>
                            </Grid>
                        </Grid>

                        <Box sx={{ mt: 2 }}>
                            <Typography variant="h5" gutterBottom >Created Durations</Typography>
                            <DataTable rows={RangeDurationRow} columns={RangeDurationColumn} tableId="table32" />
                        </Box>
                    </Box>
                    <AlertBox
                        open={alertOpen}
                        disabled={false}
                        title="Confirm Delete"
                        buttonText="Delete"
                        message={<>Are you sure want to delete this data? </>}
                        onClose={handleCloseAlert}
                        onConfirm={handleDurDelete}
                    />
                </BlankCard>
            </PageContainer>


            <Dialog open={openUpdate} onClose={handleUpdateClose} fullWidth maxWidth="sm">
                <DialogTitle>Edit Range Price</DialogTitle>
                <DialogContent>
                    <Stack>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={6}>
                                <CustomFormLabel htmlFor="range_name">
                                    Range Name {/* <RequiredStar /> */}
                                </CustomFormLabel>
                                <CustomTextField
                                    fullWidth
                                    InputProps={{ readOnly: true }}
                                    id="range_name"
                                    name="range_name"
                                    value={rangeUpdateDetails.rangeName}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <CustomFormLabel htmlFor="range_period_type">
                                    Range Period Type
                                    {/* <RequiredStar /> */}
                                </CustomFormLabel>
                                <CustomTextField
                                    fullWidth
                                    InputProps={{ readOnly: true }}
                                    id="range_period_type"
                                    name="range_period_type"
                                    value={rangeUpdateDetails.rangePeriodType}
                                />
                                {/* <FormControl fullWidth error={!!error.range_period_type}>
                                    <CustomSelect
                                        id="range_period_type"
                                        name="range_period_type"
                                        value={formData.range_period_type}
                                        onChange={handleChanges}
                                        onBlur={handleBlur}
                                        onClick={(e: any) => e.preventDefault()}
                                        style={{ pointerEvents: 'none', backgroundColor: '#ffffff' }}
                                    >
                                        <MenuItem value="day">Day</MenuItem>
                                        <MenuItem value="month">Month</MenuItem>
                                    </CustomSelect>
                                    {error.range_period_type && (
                                        <FormHelperText>{error.range_period_type}</FormHelperText>
                                    )}
                                </FormControl> */}
                            </Grid>
                        </Grid>

                        <Grid container spacing={1}>
                            <Grid item xs={12} md={6}>
                                <CustomFormLabel htmlFor="range_period_duration">
                                    Range Period Duration
                                    {/* <RequiredStar /> */}
                                </CustomFormLabel>
                                <CustomTextField
                                    fullWidth
                                    InputProps={{ readOnly: true }}
                                    id="range_period_type"
                                    name="range_period_type"
                                    value={rangeUpdateDetails.rangePeriodDur}
                                />
                                {/* <FormControl fullWidth error={!!error.range_period_duration}>
                                    <CustomSelect
                                        id="range_period_duration"
                                        name="range_period_duration"
                                        value={formData.range_period_duration}
                                        onChange={handleChanges}
                                        onBlur={handleBlur}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        MenuProps={{
                                            PaperProps: {
                                                style: { maxHeight: 200 },
                                            },
                                        }}
                                        onClick={(e: any) => e.preventDefault()}
                                        style={{ pointerEvents: 'none', backgroundColor: '#ffffff' }}
                                    >
                                        {rangePeriodType === 'day'
                                            ? inputValues.map((i) => (
                                                <MenuItem key={i} value={i}>
                                                    {i} {i === 1 ? 'Day' : 'Days'}
                                                </MenuItem>
                                            ))
                                            : inputValues.map((i) => (
                                                <MenuItem key={i} value={i}>
                                                    {i} {i === 1 ? 'Month' : 'Months'}
                                                </MenuItem>
                                            ))}
                                    </CustomSelect>
                                    {error.range_period_duration && (
                                        <FormHelperText>{error.range_period_duration}</FormHelperText>
                                    )}
                                </FormControl> */}
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <CustomFormLabel htmlFor="range_amount">
                                    Range Amount <b style={{ color: 'red' }}>*</b>
                                </CustomFormLabel>
                                <CustomTextField
                                    id="range_amount"
                                    fullWidth
                                    placeholder="00.0"
                                    name="range_amount"
                                    value={rangeUpdateDetails.rangeAmount}
                                    onChange={handleChangesUpdatePrice}
                                    // onBlur={handleBlur}
                                    onKeyPress={(e: any) => {
                                        const allowedKeys = [
                                            '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab',
                                        ];
                                        if (!allowedKeys.includes(e.key)) {
                                            e.preventDefault();
                                        }
                                    }}
                                    onWheel={PreventWheelChange}
                                // error={!!error.range_amount}
                                // helperText={error.range_amount}
                                />
                            </Grid>
                        </Grid>
                    </Stack>
                </DialogContent>
                <DialogActions sx={{ mr: 2, mb: 2 }}>

                    <Button onClick={handleUpdateClose} color="error">
                        Cancel
                    </Button>
                    <Button onClick={handleUpdate} variant="contained" color="primary">
                        Update
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AddDurations;
