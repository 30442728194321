import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip, IconButton, Typography, Grid, FormControl, MenuItem, FormHelperText, Box } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { IconEye, IconPencil, IconListDetails, IconCheck, IconX } from "@tabler/icons-react";
import PageContainer from "src/components/page-container/PageContainer";
import TableHead from "src/components/table-head";
import BlankCard from "src/components/shared/BlankCard";
import DataTable from "src/components/table/TableComponent";
import BackLink from "src/components/back-link";
import CustomTextField from "src/utils/theme-elements/CustomTextField";
import axiosServices from "src/utils/axios";
import APP_ROUTES from "src/routes/routePaths";
import { notifyMessage } from "src/utils/toastNotify";
import { getAdminAllLockerRoomNo, getAllAthletesRentedLocker, updateStatusById } from "../api-call/adminRangeUse";
import CustomFormLabel from "src/utils/theme-elements/CustomFormLabel";
import CustomSelect from "src/utils/theme-elements/CustomSelect";
import validateForm, { generateErrorInitialState } from "src/utils/FormValidate";
import { formatDate } from "src/utils/basicFormaters";

interface FormData {
  // locker_type_id: string;
  locker_room_no: string;
}

const LockerAllocation = () => {
  const navigate = useNavigate();
  const [approveDialog, setApproveDialog] = useState(false);
  const [viewRemark, setViewRemark] = useState(false);
  // const [addRemark, setAddRemark] = useState(false);
  const [confirmAddRemark, setConfirmAddRemark] = useState(false);
  const [confirmAddRejection, setConfirmAddRejection] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [reasonError, setReasonError] = useState<string>('');
  const [selectedShooterId, setSelectedShooterId] = useState<number | null>(null);
  const [rentedLocker, setRentedLocker] = useState<any[]>([]);
  const [allLocker, setAllLocker] = useState<any[]>([]);
  const [selectedLocker, setSelectedLocker] = useState("");
  const [selectedLockerId, setSelectedLockerId] = useState("");
  const [utilizationId, setUtilizationId] = useState("");
  const [lockerRoom, setLockerRoom] = useState<any[]>([]);
  const [lockerId, setLockerId] = useState<number | null>(null);
  const initialFormdata = {
    // locker_type_id: "",
    locker_room_no: "",
  };
  const [formData, setFormData] = React.useState<FormData>(initialFormdata);
  const errorInitialState: any = generateErrorInitialState(formData);
  const [error, setError] = useState(errorInitialState);

  const { id } = useParams();
  // console.log(id, "checking in Allocation page");

  const columns: GridColDef[] = [
    { field: "sr_no", headerName: "Sr. No", flex: 0.8 },
    {
      field: 'lockerTypeName',
      headerName: 'Locker Type',
      flex: 1.2,
      renderCell: (params) => (<div style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.2' }}> {params.value || 'N/A'} </div>)
    },
    {
      field: 'lockerRoomNo',
      headerName: 'Locker Room No.',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => params.value || 'N/A',
    },
    {
      field: 'duration',
      headerName: 'Duration',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => params.value + ' ' + params.row.type || 'N/A',
    },
    {
      field: 'price',
      headerName: 'Price',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        if (params.row.paymentStatus == "success" && params.value == null) {
          return '0 ₹';
        }

        return params.value ? params.value + ' ₹' : 'N/A';
      },
    },
    {
      field: 'approvalStatus',
      headerName: 'Approval Status',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <Box display="flex" alignItems="center" sx={{ cursor: 'pointer' }}>
          {params.row.adminStatus === 'pending' ? (
            <>
              <Tooltip title="Approve">
                <IconButton
                  color="success"
                  onClick={() =>
                    handleApprove({
                      LockerTypeName: params.row.lockerTypeName,
                      LockerTypeid: params.row.LockerTypeId,
                      utilizationId: params.row.id,
                    })
                  }
                >
                  <IconCheck size="22" stroke={3} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Reject">
                <IconButton color="error" onClick={() => handleReject({ utiid: params.row.id, locId: params.row.LockerTypeId })}>
                  <IconX size="22" stroke={3} />
                </IconButton>
              </Tooltip>
            </>
          ) : params.row.adminStatus === 'approved' ? (
            <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{`Apply Date: ${params.row.apply_date ? formatDate(params.row.apply_date) : '-'}\nApproval Date: ${params.row.approval_date ? formatDate(params.row.approval_date) : '-'}`}</span>} >
              <span style={{ color: '#13deb9' }}>Approved</span>
            </Tooltip>
          ) : (
            <Tooltip title={`Reason for Rejection: ${params.row.reason_for_rejection}`}>
              <span style={{ color: '#fa896a' }}>Rejected</span>
            </Tooltip>
          )}
        </Box>
      )
    },
    {
      field: 'paymentStatus',
      headerName: 'Payment Status',
      flex: 0.8,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <Box display="flex" alignItems="center" sx={{ cursor: params.row.paymentStatus === 'success' ? 'pointer' : 'default' }}>
          {params.row.paymentStatus === 'success' ? (
            <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{`Start Date: ${params.row.start_date ? formatDate(params.row.start_date) : '-'}\nEnd Date: ${params.row.end_date ? formatDate(params.row.end_date) : '-'}`}</span>}>
              <span style={{ color: '#13deb9' }}>Success</span>
            </Tooltip>
          ) : params.row.paymentStatus === 'failure' ? (
            <span style={{ color: '#fa896a' }}>Failed</span>
          ) : (
            <span>N/A</span>
          )}
        </Box>
      )
    }
  ];

  const LockerRented = (rentedLocker ?? []).map((ran, index) => ({
    sr_no: index + 1,
    id: ran.utilization_id,
    LockerTypeId: ran.LockerTypeId,
    duration: `${ran.duration} ${ran.type}`,
    price: ran.price || "NA",
    lockerTypeName: ran.lockerTypeName,
    paymentStatus: ran.paymentStatus,
    adminStatus: ran.adminStatus,
    lockerRoomNo: ran.lockerRoomNo || "NA",
    lockerIds: ran.lockerid,
    apply_date: ran.apply_date,
    approval_date: ran.approval_date,
    start_date: ran.start_date,
    end_date: ran.end_date,
    ...ran,
  }));

  // console.log(LockerRented, "for row");

  const handleApprove = async (data: any) => {
    // console.log(data, "approve id");
    setSelectedLocker(data.LockerTypeName);
    setSelectedLockerId(data.LockerTypeid);
    setUtilizationId(data.utilizationId);
    setApproveDialog(true);
  };

  const wholeError = () => {
    const newErrors = validateForm(formData);
    setError(newErrors);
  };

  // console.log(formData, "FORMDATA ");

  const handleApproveSubmit = async (e: React.FormEvent) => {
    try {
      // console.log(lockerId, "HII IDD");

      e.preventDefault();

      const check = Object.values(formData).every((e) => e !== "");
      if (!check) {
        notifyMessage.error("Check all the required fields");
        wholeError();
      } else {
        const updatedFormData = {
          admin_status: "approved",
          reason_for_rejection: "",
          locker_id: lockerId,
        };

        try {
          const updatedStatus = await updateStatusById(utilizationId, updatedFormData);
          // console.log(updatedStatus, "updated");
          const rentedLockerUser = await getAllAthletesRentedLocker();
          setAllLocker(rentedLockerUser);
          setRentedLocker(rentedLocker);
          notifyMessage.success("Status Approved");
          handleClose();
        } catch (error: any) {
          console.error("Error:", error.response.data);
          // Extract error message from the response
          const errorMessage = error.response?.data?.message?.[0]?.message;
          notifyMessage.error(errorMessage);
        }
      }
    } catch (error) {
      console.error("Error:", error);
      // notifyMessage.error("Not able to approve");
    }
  };

  const handleRejectConfirmation = async (reason: string) => {
    if (!reason.trim()) {
      // console.log('Reason is required.');
      setReasonError('Reason is required.');

      return;
    }
    try {
      const data = {
        admin_status: "rejected",
        reason_for_rejection: reason,
        locker_id: null,
      };
      const response = await updateStatusById(utilizationId, data);
      const rentedLockerUser = await getAllAthletesRentedLocker();
      setAllLocker(rentedLockerUser);
      setRentedLocker(rentedLocker);
      // notifyMessage.success("Status Approved");
      // console.log("Locker rejected response", response);
      if (response.success) {
        notifyMessage.success(`Rejected for locker `);
        setConfirmAddRejection(false);
        setUtilizationId("");
      } else {
        notifyMessage.error("Not able to reject");
      }
    } catch (error: any) {
      // console.log("Locker rejected Failed", error);
      // notifyMessage.error("Not able to reject");
    } finally {
      // confirmAddRejection(false)
    }
  };

  const handleReject = (data: any) => {
    //setSelectedShooterId(shooterId);
    setUtilizationId(data.utiid);
    setLockerId(data.locId);
    setConfirmAddRejection(true);
  };

  const handleClose = () => {
    setConfirmAddRemark(false);
    setApproveDialog(false);
    setViewRemark(false);
    setReasonError('');
    formData.locker_room_no = '';
    setError('');
    setUtilizationId("");
    setConfirmAddRejection(false);
    setRejectReason("");
    setSelectedShooterId(null);
    setLockerRoom([]);
  };

  const checkError = (fieldName: keyof FormData) => {
    const newErrors: { [key in keyof FormData]?: string } = validateForm({ [fieldName]: formData[fieldName] });
    setError((prevErrors: { [key in keyof FormData]?: string }) => ({ ...prevErrors, [fieldName]: newErrors[fieldName] }));
  };

  const createFieldHandlers = (fieldName: any) => ({
    onBlur: () => checkError(fieldName),
  });

  const handleChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
      // [name as string]: name === "locker_room_no" ? Number(value) : value,
    });

    if (name === "locker_room_no") {
      const selectedLockerId = lockerRoom.find((comp: any) => comp.locker_room_no === value)?.locker_id;
      // console.log(selectedLockerId, "lockerid for sav");
      setLockerId(selectedLockerId);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const rentedLockerUser = await getAllAthletesRentedLocker();
      setAllLocker(rentedLockerUser);
      // console.log(rentedLockerUser, "hii user locker");

      if (id) {
        // console.log(id, "id check");
        const lockerRenting = rentedLockerUser
          .filter((comp: any) => comp.id == id)
          .map((comp: any) =>
            comp.locker_utilization_data.map((period: any) => ({
              LockerTypeId: period.locker_type_id,
              duration: period.locker_period_duration,
              type: period.locker_period_type,
              price: period.payment_amount,
              lockerTypeName: period.locker_type_name,
              paymentStatus: period.payment_status,
              adminStatus: period.admin_status,
              lockerRoomNo: period.locker_room_no,
              utilization_id: period.utilization_id,
              lockerid: period.utilization_id,
              ...period,
            })),
          )
          .flat();

        // console.log(lockerRenting, "check renting");
        setRentedLocker(lockerRenting);
      }

      if (selectedLockerId) {
        // console.log(selectedLockerId, "hii iddd");
        const LockerRoomNo = await getAdminAllLockerRoomNo(selectedLockerId);
        // console.log(LockerRoomNo, "lockerRoom no in allocation");
        setLockerRoom(LockerRoomNo);
      }
    };
    fetchData();
  }, [selectedLockerId, utilizationId, id]);

  // console.log(selectedLocker, "lockertype");

  return (
    <>
      <BackLink title="Back to range usage" route={`${APP_ROUTES.ADMIN_CLUB_LOCKER_ROOM}`} />
      <PageContainer title="Athletes list" description="Athletes list">
        <BlankCard>
          <TableHead title="Applied Locker List" />
          <DataTable rows={LockerRented} columns={columns} checkbox={false} tableId="table26" />
        </BlankCard>

        <Dialog open={approveDialog} onClose={handleClose}>
          <DialogTitle style={{ width: "400px" }}>Do you want to approve?</DialogTitle>
          <DialogContent>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} lg={12}>
                <FormControl fullWidth margin="normal">
                  <CustomFormLabel sx={{ mt: 0 }} htmlFor={`range_name`}>
                    LockerType Name <b style={{ color: "red" }}>*</b>
                  </CustomFormLabel>
                  <CustomSelect
                    labelId={`range_name-label`}
                    name={`range_name`}
                    value={selectedLocker}
                    //onChange={handleChanges}
                    disabled
                    {...createFieldHandlers("range_name")}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {rentedLocker.map((option: any, i) => (
                      <MenuItem key={i} value={selectedLocker}>
                        {selectedLocker}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                  {/* {error.range_name && <FormHelperText>{error.range_name}</FormHelperText>} */}
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={12}>
                <FormControl fullWidth error={!!error.locker_room_no} margin="normal">
                  <CustomFormLabel sx={{ mt: 0 }} htmlFor={`locker_room_no`}>
                    Assign Locker <b style={{ color: "red" }}>*</b>
                  </CustomFormLabel>
                  <CustomSelect
                    labelId={`locker_room_no-label`}
                    name={`locker_room_no`}
                    value={formData.locker_room_no}
                    onChange={handleChanges}
                    {...createFieldHandlers("locker_room_no")}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {lockerRoom.map((option: any, i) => (
                      <MenuItem key={i} value={option.locker_room_no}>
                        {option.locker_room_no}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                  {error.locker_room_no && <FormHelperText>{error.locker_room_no}</FormHelperText>}
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{ marginRight: '15px' }}>
            <Button color="error" onClick={handleClose}>
              Cancel
            </Button>
            <Button color="primary" onClick={handleApproveSubmit}>
              Submit
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={confirmAddRejection} onClose={handleClose} fullWidth>
          <DialogTitle>Give Reason for Rejection</DialogTitle>
          <DialogContent style={{ marginTop: '10px' }}>
            <CustomTextField
              fullWidth
              label="Reason for rejection"
              multiline
              rows={4}
              value={rejectReason}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRejectReason(e.target.value)}
              error={Boolean(reasonError)}
              helperText={reasonError ? reasonError : ' '}
              style={{ marginTop: '10px' }}
            />
          </DialogContent>
          <DialogActions style={{ marginRight: '15px' }}>
            <Button color="error" onClick={handleClose}>
              Cancel
            </Button>
            <Button color="primary" onClick={() => handleRejectConfirmation(rejectReason)}>
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </PageContainer>
    </>
  );
};

export default LockerAllocation;
