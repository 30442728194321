import { DataGrid, GridColDef, GridPaginationModel } from "@mui/x-data-grid";
import { styled } from '@mui/system';
import { Card } from "@mui/material";
import { useEffect, useState } from "react";

interface DataTableProps {
  rows: Array<{ [key: string]: any }>;
  columns: GridColDef[];
  checkbox?: boolean;
  tableId: string;
  totalPages?: number;
  onPageChange?: (page: number) => void;
  onPageSizeChange?: (pageSize: number) => void;
}

const StyledContainer = styled('div')({
  height: 'fit-content',
  overflowY: 'scroll',
  width: '100%',
  '@media screen and (min-width: 1000px)': {
    width: '100%',
  },
});

const CollapsedContainer = styled(StyledContainer)({
  width: '100% !important',
});

const DataTable: React.FC<DataTableProps> = ({ rows, columns, checkbox, tableId, totalPages, onPageChange, onPageSizeChange }) => {
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>(() => {
    const savedPagination = localStorage.getItem(`paginationModel-${tableId}`);

    return savedPagination ? JSON.parse(savedPagination) : { page: 0, pageSize: 10 };
  });

  const customStyles = {
    card: {
      fontSize: "13px",
      boxShadow: "lg",
      "&:hover": {
        border: "1px solid #fff",
      },
      "& .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus": {
        outline: "none",
      },
      "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus-within": {
        outline: "none",
      },
    },
  };

  const handleResize = () => {
    const container = document.getElementById("data-table-container");
    if (container) {
      container.style.width = "100%";
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (onPageSizeChange) {
      onPageSizeChange(paginationModel.pageSize);
    }
  }, [paginationModel.pageSize, onPageSizeChange]);

  const handlePaginationChange = (model: GridPaginationModel) => {
    setPaginationModel(model);
    localStorage.setItem(`paginationModel-${tableId}`, JSON.stringify(model));
    if (onPageChange) {
      onPageChange(model.page);
    }
    if (onPageSizeChange) {
      onPageSizeChange(model.pageSize);
    }
  };

  return (
    <Card sx={{ boxShadow: 'lg' }}>
      {totalPages ? (
        <DataGrid
          checkboxSelection={checkbox}
          autoHeight
          sx={customStyles.card}
          rows={rows}
          columns={columns}
          paginationModel={paginationModel}
          onPaginationModelChange={handlePaginationChange}
          pageSizeOptions={[5, 10, 25, 50]}
          paginationMode="server"
          rowCount={totalPages * paginationModel.pageSize}
        />
      ) : (
        <StyledContainer id="data-table-container">
          <DataGrid
            checkboxSelection={checkbox}
            autoHeight
            sx={customStyles.card}
            rows={rows}
            columns={columns}
            paginationModel={paginationModel}
            onPaginationModelChange={handlePaginationChange}
            pageSizeOptions={[5, 10, 25, 50]}
          />
        </StyledContainer>
      )}
    </Card>
  );
};

export default DataTable;
