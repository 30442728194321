import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface DropdownState {
  dropdownValue: {
    id: string | null;
    competitionName: string | null;
  };
}

const initialState: DropdownState = {
  dropdownValue: {
    id: null,
    competitionName: null,
  },
};

const dropdownSlice = createSlice({
  name: "dropdown",
  initialState,
  reducers: {
    setDropdownValue: (state, action: PayloadAction<{ id: string; competitionName: string }>) => {
      state.dropdownValue = action.payload;
    },
    resetDropdownValue: (state) => {
      state.dropdownValue = initialState.dropdownValue;
    },
  },
});

export const { setDropdownValue, resetDropdownValue } = dropdownSlice.actions;
export default dropdownSlice.reducer;
