import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Organiser {
  name: string;
  post: string;
}

interface AddCompetitionFormState {
  competitionData: {
    comp_code: string;
    name: string;
    place: string;
    conducted_by: string;
    organisers: Organiser[];
    competition_category_id: number | null;
    target_type: number | null;
    detail_creation: string;
    late_fee_end_date: Date | null;
    reg_start_date: Date | null;
    reg_end_date: Date | null;
    cut_off_date: Date | null;
    from_date: Date | null;
    to_date: Date | null;
    is_mqs_applicable: string;
    events: number[];
    circular: string | {};
  };
  selectedEvent: number[];
  steps: {
    step1: boolean;
  };
  deletedSecretaryIds: number[];
}

const initialState: AddCompetitionFormState = {
  competitionData: {
    comp_code: "",
    name: "",
    place: "",
    conducted_by: "",
    organisers: [{ name: "", post: "" }],
    competition_category_id: null,
    target_type: null,
    detail_creation: "",
    late_fee_end_date: null,
    reg_start_date: null,
    reg_end_date: null,
    cut_off_date: null,
    from_date: null,
    to_date: null,
    is_mqs_applicable: "",
    events: [],
    circular: "",
  },
  selectedEvent: [],
  steps: {
    step1: false,
  },
  deletedSecretaryIds: [],
};

const addCompetitionFormSlice = createSlice({
  name: "addCompetitionForm",
  initialState,
  reducers: {
    setCompetitionData: (state, action: PayloadAction<AddCompetitionFormState["competitionData"]>) => {
      state.competitionData = action.payload;
    },

    setSelectedEvent: (state, action: PayloadAction<number[]>) => {
      state.selectedEvent = action.payload;
    },

    setCheckError: (state, action: PayloadAction<boolean>) => {
      state.steps.step1 = action.payload;
    },

    addDeletedSecretaryId: (state, action: PayloadAction<number[]>) => {
      state.deletedSecretaryIds.push(...action.payload);
    },

    resetDeletedSecretaryIds: (state) => {
      state.deletedSecretaryIds = [];
    },

    resetCompetitionForm: () => initialState,
  },
});

export const {
  setCompetitionData,
  setSelectedEvent,
  setCheckError,
  addDeletedSecretaryId,
  resetDeletedSecretaryIds,
  resetCompetitionForm
} = addCompetitionFormSlice.actions;

export default addCompetitionFormSlice.reducer;
