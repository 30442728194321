export const setLocalFormData = (name: string, data: any): void => {
  if (data instanceof File) {
    const reader = new FileReader();
    reader.onload = () => {
      const fileData = {
        name: data.name,
        size: data.size,
        type: data.type,
        lastModified: data.lastModified,
        content: reader.result, // Base64 encoded file content
      };
      localStorage.setItem(name, JSON.stringify(fileData));
    };
    reader.readAsDataURL(data); // Converts the file to a Base64 encoded string
  } else {
    localStorage.setItem(name, JSON.stringify(data));
  }
};


export const getLocalFormData = (name: string): any | null => {
  const data = localStorage.getItem(name);
  if (!data) return null;

  const parsedData = JSON.parse(data);

  if (parsedData && parsedData.content && parsedData.type) {
    // Reconstruct the File object
    const { name, size, type, lastModified, content } = parsedData;

    const blob = fetch(content)
      .then((res) => res.blob())
      .then((blob) => {
        return new File([blob], name, { type, lastModified });
      });

    return blob;
  }

  return parsedData;
};


export const deleteLocalFormData = (name: string): void => {
  localStorage.removeItem(name);
};
