import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import BackLink from "src/components/back-link";
import APP_ROUTES from "src/routes/routePaths";
import { AppDispatch, AppState } from "src/store/Store";
import { setConfidentialDetails, setEducationalDetails, setGaurdianDetails } from "src/store/slices/rangeUsagesClub/RangeUsages";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  Button,
  Typography,
  FormControlLabel,
  Stack,
  Grid,
  InputAdornment,
  MenuItem,
  RadioGroup,
  FormGroup,
  Card,
  Paper,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  FormHelperText,
  Divider,
  Chip,
  Box,
  ListItemText,
  TextField,
} from "@mui/material";
import validateForm, { generateErrorInitialState } from "src/utils/FormValidate";
import CustomTextField from "src/utils/theme-elements/CustomTextField";
import CustomCheckbox from "src/utils/theme-elements/CustomCheckbox";
import { capitalizeFirstLetter, formatDate } from "src/utils/basicFormaters";
import CustomRadio from "src/utils/theme-elements/CustomRadio";
import CustomSelect from "src/utils/theme-elements/CustomSelect";
import FileUpload from "src/components/upload-file/UploadFile";
import { notifyMessage } from "src/utils/toastNotify";
import axiosServices from "src/utils/axios";
import CustomFormLabel from "src/utils/theme-elements/CustomFormLabel";
import { RequiredStar } from "src/components/required-star";
import BlankCard from "src/components/shared/BlankCard";
import { getLocalFormData, setLocalFormData } from "src/services/localStorage/localServices";
import { fetchClubAthleteRangeData } from "src/store/clubRegister/RangeRegisterView";
import { UploadOnCloud } from "../../api-call/rangeUse";
import { PreventWheelChange } from "src/utils/PreventWheelChange";
import { allowedFormats } from "src/utils/fileUploadConfig";

interface ViewUploaded {
  url: string | null;
  type: string | null;
}

const RangeRegistrationForm = () => {
  const dispatch: AppDispatch = useDispatch();

  const initialFormData = getLocalFormData("range") || {
    gaurdianDetails: [
      {
        relation: "",
        name: "",
        occupation: "",
        present_address: "",
        age: "",
        staying_with_applicant: false,
        default_gaurdian: "",
      },
    ],
    educationalDetails: {
      student: "no",
      studying_college: "",
      school: [
        {
          school_name: "",
          school_address: "",
          school_entering_date: "",
          school_leaving_date: "",
          school_passed: "",
          school_phone: "",
          school_marksheet: null,
        },
      ],
      college: [
        {
          college_name: "",
          college_address: "",
          college_entering_date: "",
          college_leaving_date: "",
          college_passed: "",
          college_phone: "",
          college_marksheet: null,
        },
      ],
      marksheet: null,
    },
    confidentialDetails: {
      income: "",
      business_details: "",
      if_applied: "",
      arm_application_status: "pending",
      license_no: "",
      issues_by: "",
      valid_upto: "",
      arm_type: [
        {
          type: "",
          serial_no: "",
          caliber: "",
        },
      ],
      arm_license_proof: null,
      using_exp_firearms: "",
      details_of_course_handling_guns: "",
      details_of_sports_activity: "",
      other_informations: "",
    },
  };

  const { RangeShooter, isLoading } = useSelector((state: AppState) => state.clubAthleteRangeView);
  const RangeUsagesDetails = useSelector((state: AppState) => state.rangeUsages);
  const [formData, setFormData] = useState<any>(initialFormData);

  const [marksheetURL, setMarksheetURL] = useState<ViewUploaded>({ url: null, type: null });
  const [armLicenseURL, setArmLicenseURL] = useState<ViewUploaded>({ url: null, type: null });

  const errorInitialState: any = generateErrorInitialState(formData);
  const [error, setError] = useState(errorInitialState);

  const generateFileUrl = (file: File | string | null, setUrl: (viewUploaded: ViewUploaded) => void) => {
    if (file instanceof File) {
      const url = URL.createObjectURL(file);
      const type = file.type;
      setUrl({ url, type });

      return () => URL.revokeObjectURL(url);
    } else if (typeof file === 'string') {
      const type = getFileTypeFromUrl(file);
      setUrl({ url: file, type });
    } else {
      setUrl({ url: null, type: null });
    }
  };

  const getFileTypeFromUrl = (url: string): string | null => {
    const extension = url.split('.').pop()?.toLowerCase();

    switch (extension) {
      case 'pdf':
        return 'application/pdf';
      case 'jpg':
      case 'jpeg':
        return 'image/jpeg';
      case 'png':
        return 'image/png';
      default:
        return null;
    }
  };

  useEffect(() => {
    generateFileUrl(formData.educationalDetails.marksheet || RangeUsagesDetails?.educationalDetails?.marksheet || RangeShooter?.educationalDetails?.marksheet, setMarksheetURL);
  }, [formData.educationalDetails.marksheet, RangeUsagesDetails?.educationalDetails?.marksheet, RangeShooter?.educationalDetails?.marksheet]);

  useEffect(() => {
    generateFileUrl(formData.confidentialDetails.arm_license_proof || RangeUsagesDetails?.confidentialDetails?.arm_license_proof || RangeShooter?.confidentialDetails?.arm_license_proof, setArmLicenseURL);
  }, [formData.confidentialDetails.arm_license_proof, RangeUsagesDetails?.confidentialDetails?.arm_license_proof, RangeShooter?.confidentialDetails?.arm_license_proof]);

  // useEffect(() => {
  //   const { marksheet, arm_license_proof, ...restData } = formData;
  //   setLocalFormData("range", restData);
  //   //updateFormData();
  // }, [formData, dispatch]);

  useEffect(() => {
    // Destructure necessary data from formData
    const { marksheet, arm_license_proof, confidentialDetails, ...restData } = formData;

    // Initialize dataToStore
    const dataToStore = { ...restData };

    // Check if 'if_applied' is true
    if (confidentialDetails.if_applied === true) {
      // If true, only store specific fields in confidentialDetails
      dataToStore.confidentialDetails = {
        license_no: confidentialDetails.license_no,
        issues_by: confidentialDetails.issues_by,
        valid_upto: confidentialDetails.valid_upto,
        arm_type: confidentialDetails.arm_type,
        arm_license_proof: confidentialDetails.arm_license_proof,
      };
    } else {
      // If false, reset to initial state (default values)
      dataToStore.confidentialDetails = {
        license_no: "",
        issues_by: "",
        valid_upto: "",
        arm_type: [
          {
            type: "",
            serial_no: "",
            caliber: "",
          },
        ],
        arm_license_proof: null,
      };
    }

    // Conditionally add marksheet if 'student' is 'yes'
    if (formData.student === "yes") {
      dataToStore.educationalDetails = {
        ...dataToStore.educationalDetails,
        marksheet: formData.educationalDetails.marksheet,
      };
    }

    // Set the local form data based on the conditions
    setLocalFormData("range", dataToStore);

    // Optionally call updateFormData() if needed
    // updateFormData();
  }, [formData, dispatch]);


  useEffect(() => {
    dispatch(fetchClubAthleteRangeData());
  }, [dispatch]);

  const createFieldHandlers = (i: number, fieldType: string) => ({
    onChange: (e: any) => {
      const { name, value, type, checked } = e.target;
      let fieldValue = type === "checkbox" ? checked : value;

      // Handle specific validations or actions based on field name
      if ((name === "school_phone" || name === "college_phone") && type === "number" && value.length > 10) {
        return;
      }

      // Handle radio button logic
      if (type === "radio" && name === "default_gaurdian") {
        fieldValue = "1"; // Assuming setting a specific value for radio
        setFormData((prev: any) => ({
          ...prev,
          gaurdianDetails: prev.gaurdianDetails.map((item: any, index: number) =>
            index === i ? { ...item, default_gaurdian: "1" } : { ...item, default_gaurdian: "" }
          ),
        }));
      }

      setFormData((prev: any) => {
        if (fieldType === "gaurdianDetails") {
          return {
            ...prev,
            [fieldType]: prev[fieldType]?.map((item: any, index: number) =>
              index === i ? { ...item, [name]: fieldValue } : item
            ) ?? [],
          };
        } else if (fieldType === "educationalDetails") {
          // Validate entering and leaving dates for school and college
          if (
            name === "school_entering_date" ||
            name === "school_leaving_date" ||
            name === "college_entering_date" ||
            name === "college_leaving_date"
          ) {
            const schoolEnteringDate = name === "school_entering_date" ? fieldValue : prev.educationalDetails.school[i]?.school_entering_date;
            const schoolLeavingDate = name === "school_leaving_date" ? fieldValue : prev.educationalDetails.school[i]?.school_leaving_date;
            const collegeEnteringDate = name === "college_entering_date" ? fieldValue : prev.educationalDetails.college[i]?.college_entering_date;
            const collegeLeavingDate = name === "college_leaving_date" ? fieldValue : prev.educationalDetails.college[i]?.college_leaving_date;

            const errorsToUpdate: any = {};

            if (schoolEnteringDate && schoolLeavingDate && new Date(schoolEnteringDate) >= new Date(schoolLeavingDate)) {
              errorsToUpdate.school = {
                ...prev.error?.educationalDetails?.school,
                [i]: {
                  ...prev.error?.educationalDetails?.school?.[i],
                  school_leaving_date: "Leaving date must be after entering date",
                },
              };
            } else {
              errorsToUpdate.school = {
                ...prev.error?.educationalDetails?.school,
                [i]: {
                  ...prev.error?.educationalDetails?.school?.[i],
                  school_leaving_date: "",
                },
              };
            }

            if (collegeEnteringDate && collegeLeavingDate && new Date(collegeEnteringDate) >= new Date(collegeLeavingDate)) {
              errorsToUpdate.college = {
                ...prev.error?.educationalDetails?.college,
                [i]: {
                  ...prev.error?.educationalDetails?.college?.[i],
                  college_leaving_date: "Leaving date must be after entering date",
                },
              };
            } else {
              errorsToUpdate.college = {
                ...prev.error?.educationalDetails?.college,
                [i]: {
                  ...prev.error?.educationalDetails?.college?.[i],
                  college_leaving_date: "",
                },
              };
            }

            setError((prevError: any) => ({
              ...prevError,
              educationalDetails: {
                ...prevError.educationalDetails,
                ...errorsToUpdate,
              },
            }));
          }

          return {
            ...prev,
            educationalDetails: {
              ...prev.educationalDetails,
              [name]: fieldValue,
              school: prev.educationalDetails.school?.map((item: any, index: number) =>
                index === i ? { ...item, [name]: fieldValue } : item
              ) ?? [],
              college: prev.educationalDetails.college?.map((item: any, index: number) =>
                index === i ? { ...item, [name]: fieldValue } : item
              ) ?? [],
            },
          };
          // } else if (fieldType === "confidentialDetails") {
          //   const today = new Date().toISOString().split('T')[0]; // Get today's date in 'YYYY-MM-DD' format

          //   if (name === "valid_upto" && new Date(value) < new Date(today)) {
          //     setError((prevError: any) => ({
          //       ...prevError,
          //       confidentialDetails: {
          //         ...prevError.confidentialDetails,
          //         valid_upto: "Valid upto date cannot be in the past",
          //       },
          //     }));
          //   } else {
          //     setError((prevError: any) => ({
          //       ...prevError,
          //       confidentialDetails: {
          //         ...prevError.confidentialDetails,
          //         valid_upto: "", // Clear error if condition is satisfied
          //       },
          //     }));
          //   }

          //   return {
          //     ...prev,
          //     confidentialDetails: {
          //       ...prev.confidentialDetails,
          //       [name]: fieldValue,
          //       arm_type: prev.confidentialDetails.arm_type?.map((item: any, index: number) =>
          //         index === i ? { ...item, [name]: fieldValue } : item
          //       ) ?? [],
          //     },
          //   };
          // }

        } else if (fieldType === "confidentialDetails") {
          const today = new Date().toISOString().split('T')[0]; // Get today's date in 'YYYY-MM-DD' format

          // If 'if_applied' is false, reset specific fields to default values
          if (formData.confidentialDetails.if_applied === false) {
            setError((prevError: any) => ({
              ...prevError,
              confidentialDetails: {
                ...prevError.confidentialDetails,
                valid_upto: "", // Clear any previous errors
              },
            }));

            // Reset the formData fields related to the arm license
            return {
              ...prev,
              confidentialDetails: {
                ...prev.confidentialDetails,
                license_no: "",
                issues_by: "",
                valid_upto: "",
                arm_type: [
                  {
                    type: "",
                    serial_no: "",
                    caliber: "",
                  },
                ],
                arm_license_proof: null,
              },
            };
          }

          // Validation for valid_upto if 'if_applied' is true
          if (formData.confidentialDetails.if_applied === true) {
            if (name === "valid_upto" && new Date(value) < new Date(today)) {
              setError((prevError: any) => ({
                ...prevError,
                confidentialDetails: {
                  ...prevError.confidentialDetails,
                  valid_upto: "Valid upto date cannot be in the past",
                },
              }));
            } else {
              setError((prevError: any) => ({
                ...prevError,
                confidentialDetails: {
                  ...prevError.confidentialDetails,
                  valid_upto: "", // Clear error if the condition is satisfied
                },
              }));
            }
          }

          return {
            ...prev,
            confidentialDetails: {
              ...prev.confidentialDetails,
              [name]: fieldValue,
              arm_type: prev.confidentialDetails.arm_type?.map((item: any, index: number) =>
                index === i ? { ...item, [name]: fieldValue } : item
              ) ?? [],
            },
          };
        }

        return prev;
      });
    },

    onBlur: () => singleError(fieldType),
  });

  useEffect(() => {
    if (RangeShooter && RangeShooter.range_usage_status === "rejected") {
      let armDetails: any = null;
      let familyDetails: any = null;
      const schoolDetails = Object.values(JSON.parse(RangeShooter.school_details));
      const collegeDetails = Object.values(JSON.parse(RangeShooter.college_details));

      if (RangeShooter.arm_details) {
        try {
          const parsedArmDetails = JSON.parse(RangeShooter.arm_details);
          armDetails = parsedArmDetails?.armDetails || null;
        } catch (error) {
          console.error("Error parsing arm_details:", error);
        }
      }

      if (RangeShooter.family_details) {
        try {
          familyDetails = JSON.parse(RangeShooter.family_details);
        } catch (error) {
          console.error("Error parsing family_details:", error);
        }
      }

      setFormData((prevData: any) => ({
        ...prevData,
        gaurdianDetails: familyDetails,

        educationalDetails: {
          student: RangeShooter.student,
          studying_college: RangeShooter.studying_college,
          school: schoolDetails,
          college: collegeDetails,
          marksheet: RangeShooter.marksheet,
        },
        confidentialDetails: {
          income: RangeShooter.income,
          business_details: RangeShooter.business_details,
          if_applied: RangeShooter.is_arm_license_applied ? "yes" : "no",
          arm_application_status: RangeShooter.arm_application_status,
          license_no: RangeShooter.arm_license_no,
          issues_by: RangeShooter.arm_license_issued_by,
          valid_upto: new Date(RangeShooter.arm_license_validity).toISOString().split("T")[0],
          arm_type: armDetails,
          arm_license_proof: RangeShooter.arm_proof,
          using_exp_firearms: RangeShooter.experience_in_firearms,
          details_of_course_handling_guns: RangeShooter.details_of_course,
          details_of_sports_activity: RangeShooter.details_of_sports,
          other_informations: RangeShooter.other_information,
        },
      }));
    }
  }, []);

  const singleError = (fieldName: string) => {
    const validationFields: any = { ...formData };

    if (fieldName === "school_entering_date" || fieldName === "school_leaving_date" ||
      fieldName === "college_entering_date" || fieldName === "college_leaving_date" ||
      fieldName === "valid_upto") {
      validationFields.school_entering_date = formData.school_entering_date;
      validationFields.school_leaving_date = formData.school_leaving_date;
      validationFields.college_entering_date = formData.college_entering_date;
      validationFields.college_leaving_date = formData.college_leaving_date;
      validationFields.valid_upto = formData.valid_upto;
    } else {
      validationFields[fieldName] = formData[fieldName];
    }

    const newErrors = validateForm(validationFields);

    setError((prevErrors: any) => ({ ...prevErrors, [fieldName]: newErrors[fieldName] }));

    // console.log(newErrors, fieldName, "all errors");
  };


  const handleAddFields = (type: string) => {
    if (type === "gaurdianDetails") {
      setFormData((prev: any) => ({
        ...prev,
        gaurdianDetails: [
          ...prev.gaurdianDetails,
          {
            relation: "",
            name: "",
            occupation: "",
            present_address: "",
            age: "",
            staying_with_applicant: false,
            default_gaurdian: "",
          },
        ],
      }));
    }
    if (type === "schoolDetails") {
      setFormData((prev: any) => ({
        ...prev,
        educationalDetails: {
          ...prev.educationalDetails,
          school: [
            ...prev.educationalDetails.school,
            {
              school_name: "",
              school_address: "",
              school_entering_date: "",
              school_leaving_date: "",
              school_passed: "",
              school_phone: "",
              school_marksheet: null,
            },
          ],
        },
      }));
    }
    if (type === "collegeDetails") {
      setFormData((prev: any) => ({
        ...prev,
        educationalDetails: {
          ...prev.educationalDetails,
          college: [
            ...prev.educationalDetails.college,
            {
              college_name: "",
              college_address: "",
              college_entering_date: "",
              college_leaving_date: "",
              college_passed: "",
              college_phone: "",
              college_marksheet: null,
            },
          ],
        },
      }));
    } else if (type === "confidentialDetails") {
      setFormData((prev: any) => ({
        ...prev,
        confidentialDetails: {
          ...prev.confidentialDetails,
          arm_type: [
            ...prev.confidentialDetails.arm_type,
            {
              type: "revolver",
              serial_no: "",
              caliber: "",
            },
          ],
        },
      }));
    }
  };

  const handleRemoveFields = (type: string) => {
    if (type === "gaurdianDetails") {
      if (formData.gaurdianDetails.length > 1) {
        setFormData((prev: any) => {
          const updateFields = [...prev.gaurdianDetails];
          updateFields.pop();

          return {
            ...prev,
            gaurdianDetails: updateFields,
          };
        });
      }
    }
    if (type === "schoolDetails") {
      if (formData?.educationalDetails.school.length > 1) {
        setFormData((prev: any) => {
          const updatedSchools = [...prev.educationalDetails.school];
          updatedSchools.pop();

          return {
            ...prev,
            educationalDetails: {
              ...prev.educationalDetails,
              school: updatedSchools,
            },
          };
        });
      }
    }
    if (type === "collegeDetails") {
      if (formData?.educationalDetails.college.length > 1) {
        setFormData((prev: any) => {
          const updatedCollege = [...prev.educationalDetails.college];
          updatedCollege.pop();

          return {
            ...prev,
            educationalDetails: {
              ...prev.educationalDetails,
              college: updatedCollege,
            },
          };
        });
      }
    } else if (type === "confidentialDetails") {
      if (formData?.confidentialDetails.arm_type.length > 1) {
        setFormData((prev: any) => {
          const updatedArmTypes = [...prev.confidentialDetails.arm_type];
          updatedArmTypes.pop();

          return {
            ...prev,
            confidentialDetails: {
              ...prev.confidentialDetails,
              arm_type: updatedArmTypes,
            },
          };
        });
      }
    }
  };

  const handleArmLicenseUpload = async (files: File[] | null) => {
    if (files && files.length > 0) {
      const uploadedFile = files[0];
      setFormData((prev: any) => {
        const updatedConfidentialDetails = {
          ...prev.confidentialDetails,
          arm_license_proof: uploadedFile,
        };

        dispatch(setConfidentialDetails(updatedConfidentialDetails));

        return {
          ...prev,
          confidentialDetails: updatedConfidentialDetails,
        };
      });
    }
  };

  const handleMarksheetUpload = async (files: File[] | null) => {
    if (files && files.length > 0) {
      const uploadedFile = files[0];
      setFormData((prev: any) => {
        const updatedEducationalDetails = {
          ...prev.educationalDetails,
          marksheet: uploadedFile,
        };

        dispatch(setEducationalDetails(updatedEducationalDetails));

        return {
          ...prev,
          educationalDetails: updatedEducationalDetails,
        };
      });
    }
  };

  // // console.log(RangeShooter, "shhoter data");
  // // console.log(formData.educationalDetails, "Edu");
  // // console.log(formData.educationalDetails.marksheet, formData.confidentialDetails.arm_license_proof, "File");
  // // console.log(formData.gaurdianDetails, "GAURD");
  // // console.log(formData.confidentialDetails, "confid");
  // // console.log(error, "error chec");

  useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(setGaurdianDetails(formData.gaurdianDetails));
      dispatch(setEducationalDetails(formData.educationalDetails));
      dispatch(setConfidentialDetails(formData.confidentialDetails));
    }, 200);

    return () => clearInterval(intervalId);
  }, [formData, dispatch]);

  const handleKeyPress = (e: any) => {
    const allowedKeys = [
      'Delete',
      'ArrowLeft',
      'ArrowRight',
      'Tab',
      'Backspace',
      ' '
    ];

    const isAlphabet = (key: any) => /^[a-zA-Z]$/.test(key);

    if (!allowedKeys.includes(e.key) && !isAlphabet(e.key)) {
      e.preventDefault();
    }
  };

  const handleNumberKeyPress = (e: any) => {
    const allowedKeys = [
      'Backspace',
      'Delete',
      'ArrowLeft',
      'ArrowRight',
      'Tab'
    ];

    if (allowedKeys.includes(e.key)) {
      return;
    }

    const newValue = e.target.value + e.key;
    const numberValue = parseInt(newValue, 10);

    if (isNaN(numberValue) || numberValue >= 101 || numberValue == 0) {
      e.preventDefault();
    }
  };

  return (
    <>
      <Card sx={{ border: "1px solid #ccc", paddingTop: "10px" }}>
        <Box sx={{ border: "1px solid #ccc", p: 1 }}>
          <Box sx={{ mt: 2, backgroundColor: "grey.300" }} border={0.1} borderColor="grey.400" padding={1} marginLeft={1.5}>
            <Typography variant="h5" textAlign="center">
              Parent/Gaurdian Details
            </Typography>
          </Box>
          <Box my={2}>
            <Card sx={{ border: "1px solid #ccc", paddingTop: "40px" }}>
              <FormGroup sx={{ position: "relative" }}>
                <Box sx={{ background: "none", boxShadow: "none" }}>
                  <div style={{ display: "flex", width: "150px", alignItems: "center", position: "absolute", top: "-35px", right: "0px" }}>
                    <Button variant="outlined" sx={{ width: "20px", textAlign: "center" }} onClick={() => handleAddFields("gaurdianDetails")}>
                      <AddIcon />
                    </Button>
                    {formData?.gaurdianDetails?.length > 1 && (
                      <Button variant="outlined" sx={{ width: "20px", textAlign: "center", ml: 0.5 }} onClick={() => handleRemoveFields("gaurdianDetails")}>
                        <RemoveIcon />
                      </Button>
                    )}
                  </div>
                  {formData?.gaurdianDetails?.map((details: any, i: any) => {
                    return (
                      <Grid container spacing={2} mt={0.3} key={i}>
                        <Grid item xs={12} lg={4}>
                          <CustomTextField
                            select
                            name="relation"
                            value={details.relation}
                            {...createFieldHandlers(i, "gaurdianDetails")}
                            label="Relation"
                            error={!!error.guardianDetails?.[i]?.relation}
                            helperText={error.guardianDetails?.[i]?.relation ? 'Relation is required.' : ''}
                            fullWidth
                          >
                            <MenuItem key='' value=''>None</MenuItem>
                            <MenuItem value="husband/wife">Husband/Wife</MenuItem>
                            <MenuItem value="father">Father</MenuItem>
                            <MenuItem value="mother">Mother</MenuItem>
                            <MenuItem value="sister">Sister</MenuItem>
                            <MenuItem value="brother">Brother</MenuItem>
                            <MenuItem value="gaurdian">Gaurdian</MenuItem>
                          </CustomTextField>
                        </Grid>
                        <Grid item xs={4} lg={4}>
                          <FormControl fullWidth>
                            <CustomTextField
                              id={`name-${i}`}
                              label="Name"
                              name={`name`}
                              value={details.name}
                              onKeyPress={handleKeyPress}
                              error={!!error.gaurdianDetails?.[i]?.name}
                              helperText={error.gaurdianDetails?.[i]?.name}
                              {...createFieldHandlers(i, "gaurdianDetails")}
                              fullWidth
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={4} lg={4}>
                          <FormControl fullWidth>
                            <CustomTextField
                              id={`occupation-${i}`}
                              label="Occupation"
                              name={`occupation`}
                              value={details.occupation}
                              onKeyPress={handleKeyPress}
                              error={!!error.gaurdianDetails?.[i]?.occupation}
                              helperText={error.gaurdianDetails?.[i]?.occupation}
                              {...createFieldHandlers(i, "gaurdianDetails")}
                              fullWidth
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={4} lg={4}>
                          <FormControl fullWidth>
                            <CustomTextField
                              id={`present_address-${i}`}
                              label="Present Address"
                              name={`present_address`}
                              value={details.present_address}
                              error={!!error.gaurdianDetails?.[i]?.present_address}
                              helperText={error.gaurdianDetails?.[i]?.present_address}
                              {...createFieldHandlers(i, "gaurdianDetails")}
                              fullWidth
                            />
                          </FormControl>
                        </Grid>
                        <Grid item lg={3}>
                          <Grid container>
                            <Grid item xs={4} lg={8}>
                              <FormControl fullWidth>
                                <CustomTextField
                                  id={`age-${i}`}
                                  label="Age"
                                  name={`age`}
                                  value={details.age}
                                  type="number"
                                  onWheel={PreventWheelChange}
                                  onKeyPress={handleNumberKeyPress}
                                  error={!!error.gaurdianDetails?.[i]?.age}
                                  helperText={error.gaurdianDetails?.[i]?.age}
                                  {...createFieldHandlers(i, "gaurdianDetails")}
                                  fullWidth
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <div>
                            {["staying with applicant"].map((applicant) => (
                              <FormControlLabel
                                key={applicant}
                                control={
                                  <CustomCheckbox
                                    name="staying_with_applicant"
                                    value={applicant}
                                    checked={details.staying_with_applicant === true}
                                    {...createFieldHandlers(i, "gaurdianDetails")}
                                  />
                                }
                                label={capitalizeFirstLetter(applicant)}
                              />
                            ))}
                          </div>
                        </Grid>
                        <Grid item xs={4} lg={2}>
                          <RadioGroup
                            row
                            aria-label="position"
                            name="default_gaurdian"
                            value={formData.gaurdianDetails.default_gaurdian}
                            {...createFieldHandlers(i, "gaurdianDetails")}
                          >
                            {["1"].map((event) => (
                              <FormControlLabel
                                key={event}
                                name="default_gaurdian"
                                value={event}
                                control={<CustomRadio color="primary" />}
                                label={capitalizeFirstLetter("Parent/Gaurdian")}
                                checked={formData.gaurdianDetails[i].default_gaurdian === "1"}
                              />
                            ))}
                          </RadioGroup>
                        </Grid>
                        <Grid item lg={12}>
                          {i !== formData.gaurdianDetails.length - 1 && (
                            <Divider>
                              <Chip label="new" size="small" color="error" variant="outlined" />
                            </Divider>
                          )}
                        </Grid>
                      </Grid>
                    );
                  })}
                </Box>
              </FormGroup>
            </Card>
          </Box>
        </Box>
        <Box sx={{ border: "1px solid #ccc", p: 1, marginTop: 2 }}>
          <Box sx={{ mt: 2, backgroundColor: "grey.300" }} border={0.1} borderColor="grey.400" padding={1} marginLeft={1.5}>
            <Typography variant="h5" textAlign="center">
              Educational Details
            </Typography>
          </Box>
          <Grid container sx={{ padding: 1 }} spacing={3}>
            <Grid item xs={6}>
              <Card sx={{ marginTop: "25px", display: "flex", alignItems: "center", gap: "20px", py: 0.4 }}>
                <Typography variant="subtitle2" marginBottom={0.5} sx={{}}>
                  Are you student? <b style={{ color: "red" }}>*</b>{" "}
                </Typography>
                <RadioGroup row aria-label="student" value={formData.educationalDetails.student}>
                  <FormControlLabel
                    name="student"
                    value="yes"
                    checked={formData.educationalDetails.student === "yes"}
                    {...createFieldHandlers(0, "educationalDetails")}
                    control={<CustomRadio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    name="student"
                    value="no"
                    checked={formData.educationalDetails.student === "no"}
                    {...createFieldHandlers(0, "educationalDetails")}
                    control={<CustomRadio />}
                    label="No"
                  />
                </RadioGroup>
              </Card>
            </Grid>

            {formData.educationalDetails.student === "yes" && (
              <Grid item xs={6}>
                <Card sx={{ marginTop: "25px", display: "flex", alignItems: "center", gap: "20px", py: 0.4 }}>
                  <Typography variant="subtitle2" marginBottom={0.5} sx={{}}>
                    Are you studying in school or college? <b style={{ color: "red" }}>*</b>{" "}
                  </Typography>
                  <RadioGroup row aria-label="position" name="studying_college">
                    <FormControlLabel
                      name="studying_college"
                      value="school"
                      checked={formData.educationalDetails.studying_college === "school"}
                      {...createFieldHandlers(0, "educationalDetails")}
                      control={<CustomRadio />}
                      label="school"
                    />
                    <FormControlLabel
                      name="studying_college"
                      value="college"
                      checked={formData.educationalDetails.studying_college === "college"}
                      {...createFieldHandlers(0, "educationalDetails")}
                      control={<CustomRadio />}
                      label="college"
                    />
                  </RadioGroup>
                </Card>
              </Grid>
            )}
          </Grid>

          {(formData.educationalDetails.student != "no" ||
            formData.educationalDetails.student != "yes" ||
            (formData.educationalDetails.student === "yes" && formData.educationalDetails.studying_college === "school")) && (
              <Card sx={{ border: "1px solid #ccc", mt: 2, paddingTop: "30px" }}>
                <Chip sx={{ marginLeft: "20px" }} label="School:  education qualification showing places of education with year" size="small" color="error" variant="outlined" />
                <FormGroup sx={{ position: "relative" }}>
                  <Box sx={{ background: "none", boxShadow: "none" }}>
                    <div style={{ display: "flex", width: "150px", alignItems: "center", position: "absolute", top: "-35px", right: "0px" }}>
                      <Button variant="outlined" sx={{ width: "20px", textAlign: "center" }} onClick={() => handleAddFields("schoolDetails")}>
                        <AddIcon />
                      </Button>
                      {formData?.educationalDetails?.school?.length > 1 && (
                        <Button variant="outlined" sx={{ width: "20px", textAlign: "center", ml: 0.5 }} onClick={() => handleRemoveFields("schoolDetails")}>
                          <RemoveIcon />
                        </Button>
                      )}
                    </div>
                    {formData?.educationalDetails?.school?.map((details: any, i: any) => {
                      return (
                        <Grid container spacing={2} mt={0.3} key={i}>
                          <Grid item xs={4} lg={4}>
                            <FormControl fullWidth>
                              <CustomFormLabel sx={{ mt: 0 }} htmlFor="country-text">
                                {" "}
                                School Name <b style={{ color: "red" }}>*</b>{" "}
                              </CustomFormLabel>
                              <CustomTextField
                                id={`school_name-${i}`}
                                name={`school_name`}
                                value={details.school_name}
                                error={!!error.educationalDetails?.school?.[i]?.school_name}
                                helperText={error.educationalDetails?.school?.[i]?.school_name}
                                {...createFieldHandlers(i, "educationalDetails")}
                                fullWidth
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={4} lg={4}>
                            <FormControl fullWidth>
                              <CustomFormLabel sx={{ mt: 0 }} htmlFor="country-text">
                                {" "}
                                School Address <b style={{ color: "red" }}>*</b>{" "}
                              </CustomFormLabel>
                              <CustomTextField
                                id={`school_address-${i}`}
                                //label="School Address"
                                name={`school_address`}
                                value={details.school_address}
                                error={!!error.educationalDetails?.school?.[i]?.school_address}
                                helperText={error.educationalDetails?.school?.[i]?.school_address}
                                {...createFieldHandlers(i, "educationalDetails")}
                                fullWidth
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={4} lg={4}>
                            <FormControl fullWidth>
                              <CustomFormLabel sx={{ mt: 0 }} htmlFor="country-text">
                                {" "}
                                Entering Date <b style={{ color: "red" }}>*</b>{" "}
                              </CustomFormLabel>
                              <CustomTextField
                                id={`school_entering_date-${i}`}
                                name={`school_entering_date`}
                                type="Date"
                                value={details.school_entering_date}
                                error={!!error.educationalDetails?.school?.[i]?.school_entering_date}
                                helperText={error.educationalDetails?.school?.[i]?.school_entering_date}
                                {...createFieldHandlers(i, "educationalDetails")}
                                fullWidth
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={4} lg={4}>
                            <FormControl fullWidth>
                              <CustomFormLabel sx={{ mt: 0 }} htmlFor="country-text">
                                {" "}
                                Leaving Date <b style={{ color: "red" }}>*</b>{" "}
                              </CustomFormLabel>
                              <CustomTextField
                                id={`school_leaving_date-${i}`}
                                name={`school_leaving_date`}
                                type="Date"
                                value={details.school_leaving_date}
                                error={!!error.educationalDetails?.school?.[i]?.school_leaving_date}
                                helperText={error.educationalDetails?.school?.[i]?.school_leaving_date}
                                {...createFieldHandlers(i, "educationalDetails")}
                                fullWidth
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={4} lg={4}>
                            <FormControl fullWidth>
                              <CustomFormLabel sx={{ mt: 0 }} htmlFor="country-text">
                                {" "}
                                School Passed in Year<b style={{ color: "red" }}>*</b>{" "}
                              </CustomFormLabel>
                              <CustomTextField
                                id={`school_passed-${i}`}
                                name={`school_passed`}
                                type="number"
                                value={details.school_passed}
                                onWheel={PreventWheelChange}
                                onKeyPress={(e: any) => {
                                  const allowedKeys = [
                                    '0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
                                    'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'
                                  ];

                                  if (!allowedKeys.includes(e.key)) {
                                    e.preventDefault();

                                    return;
                                  }

                                  const newValue = e.target.value + e.key;
                                  const numberValue = parseInt(newValue, 10);

                                  if (newValue.length > 4 || isNaN(numberValue) || numberValue == 0) {
                                    e.preventDefault();
                                  }
                                }}
                                error={!!error.educationalDetails?.school?.[i]?.school_passed}
                                helperText={error.educationalDetails?.school?.[i]?.school_passed}
                                {...createFieldHandlers(i, "educationalDetails")}
                                fullWidth
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={4} lg={4}>
                            <FormControl fullWidth>
                              <CustomFormLabel sx={{ mt: 0 }} htmlFor="country-text">
                                {" "}
                                School Phone <b style={{ color: "red" }}>*</b>{" "}
                              </CustomFormLabel>
                              <CustomTextField
                                id={`school_phone-${i}`}
                                name={`school_phone`}
                                onWheel={PreventWheelChange}
                                value={details.school_phone}
                                type="number"
                                error={!!error.educationalDetails?.school?.[i]?.school_phone}
                                helperText={error.educationalDetails?.school?.[i]?.school_phone}
                                {...createFieldHandlers(i, "educationalDetails")}
                                fullWidth
                              />
                            </FormControl>
                          </Grid>
                          <Grid item lg={12}>
                            {i !== formData?.educationalDetails.school.length - 1 && (
                              <Divider>
                                <Chip label="new" size="small" color="error" variant="outlined" />
                              </Divider>
                            )}
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Box>
                </FormGroup>
              </Card>
            )}

          {(formData.educationalDetails.student === "no" || (formData.educationalDetails.student === "yes" && formData.educationalDetails.studying_college !== "school")) && (
            <Card sx={{ border: "1px solid #ccc", mt: 2, paddingTop: "30px" }}>
              <Chip sx={{ marginLeft: "20px" }} label="College:  education qualification showing places of education with year" size="small" color="error" variant="outlined" />
              <FormGroup sx={{ position: "relative" }}>
                <Box sx={{ background: "none", boxShadow: "none" }}>
                  <div style={{ display: "flex", width: "150px", alignItems: "center", position: "absolute", top: "-35px", right: "0px" }}>
                    <Button variant="outlined" sx={{ width: "20px", textAlign: "center" }} onClick={() => handleAddFields("collegeDetails")}>
                      <AddIcon />
                    </Button>
                    {formData?.educationalDetails?.college?.length > 1 && (
                      <Button variant="outlined" sx={{ width: "20px", textAlign: "center", ml: 0.5 }} onClick={() => handleRemoveFields("collegeDetails")}>
                        <RemoveIcon />
                      </Button>
                    )}
                  </div>
                  {formData?.educationalDetails?.college?.map((details: any, i: any) => {
                    return (
                      <Grid container spacing={2} mt={0.3} key={i}>
                        <Grid item xs={4} lg={4}>
                          <FormControl fullWidth>
                            <CustomFormLabel sx={{ mt: 0 }} htmlFor="country-text">
                              {" "}
                              College Name <b style={{ color: "red" }}>*</b>{" "}
                            </CustomFormLabel>
                            <CustomTextField
                              id={`college_name-${i}`}
                              name={`college_name`}
                              value={details.college_name}
                              error={!!error.educationalDetails?.college?.[i]?.college_name}
                              helperText={error.educationalDetails?.college?.[i]?.college_name}
                              {...createFieldHandlers(i, "educationalDetails")}
                              fullWidth
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={4} lg={4}>
                          <FormControl fullWidth>
                            <CustomFormLabel sx={{ mt: 0 }} htmlFor="country-text">
                              {" "}
                              College Address <b style={{ color: "red" }}>*</b>{" "}
                            </CustomFormLabel>
                            <CustomTextField
                              id={`college_address-${i}`}
                              name={`college_address`}
                              value={details.college_address}
                              error={!!error.educationalDetails?.college?.[i]?.college_address}
                              helperText={error.educationalDetails?.college?.[i]?.college_address}
                              {...createFieldHandlers(i, "educationalDetails")}
                              fullWidth
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={4} lg={4}>
                          <FormControl fullWidth>
                            <CustomFormLabel sx={{ mt: 0 }} htmlFor="country-text">
                              {" "}
                              Entering Date <b style={{ color: "red" }}>*</b>{" "}
                            </CustomFormLabel>
                            <CustomTextField
                              id={`college_entering_date-${i}`}
                              name={`college_entering_date`}
                              type="Date"
                              value={details.college_entering_date}
                              error={!!error.educationalDetails?.college?.[i]?.college_entering_date}
                              helperText={error.educationalDetails?.college?.[i]?.college_entering_date}
                              {...createFieldHandlers(i, "educationalDetails")}
                              fullWidth
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={4} lg={4}>
                          <FormControl fullWidth>
                            <CustomFormLabel sx={{ mt: 0 }} htmlFor="country-text">
                              {" "}
                              Leaving Date <b style={{ color: "red" }}>*</b>{" "}
                            </CustomFormLabel>
                            <CustomTextField
                              id={`college_leaving_date-${i}`}
                              name={`college_leaving_date`}
                              type="Date"
                              value={details.college_leaving_date}
                              error={!!error.educationalDetails?.college?.[i]?.college_leaving_date}
                              helperText={error.educationalDetails?.college?.[i]?.college_leaving_date}
                              {...createFieldHandlers(i, "educationalDetails")}
                              fullWidth
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={4} lg={4}>
                          <FormControl fullWidth>
                            <CustomFormLabel sx={{ mt: 0 }} htmlFor="country-text">
                              {" "}
                              College Passed in Year<b style={{ color: "red" }}>*</b>{" "}
                            </CustomFormLabel>
                            <CustomTextField
                              id={`college_passed-${i}`}
                              // label="College Passed"
                              onWheel={PreventWheelChange}
                              name={`college_passed`}
                              //type="Date"
                              value={details.college_passed}
                              onKeyPress={(e: any) => {
                                const allowedKeys = [
                                  '0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
                                  'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'
                                ];

                                if (!allowedKeys.includes(e.key)) {
                                  e.preventDefault();

                                  return;
                                }

                                const newValue = e.target.value + e.key;
                                const numberValue = parseInt(newValue, 10);

                                if (newValue.length > 4 || isNaN(numberValue) || numberValue == 0) {
                                  e.preventDefault();
                                }
                              }}
                              error={!!error.educationalDetails?.college?.[i]?.college_passed}
                              helperText={error.educationalDetails?.college?.[i]?.college_passed}
                              {...createFieldHandlers(i, "educationalDetails")}
                              fullWidth
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={4} lg={4}>
                          <FormControl fullWidth>
                            <CustomFormLabel sx={{ mt: 0 }} htmlFor="country-text">
                              {" "}
                              College Phone <b style={{ color: "red" }}>*</b>{" "}
                            </CustomFormLabel>
                            <CustomTextField
                              id={`college_phone-${i}`}
                              name={`college_phone`}
                              onWheel={PreventWheelChange}
                              value={details.college_phone}
                              type="number"
                              error={!!error.educationalDetails?.college?.[i]?.college_phone}
                              helperText={error.educationalDetails?.college?.[i]?.college_phone}
                              {...createFieldHandlers(i, "educationalDetails")}
                              fullWidth
                            />
                          </FormControl>
                        </Grid>
                        <Grid item lg={12}>
                          {i !== formData?.educationalDetails.college.length - 1 && (
                            <Divider>
                              <Chip label="new" size="small" color="error" variant="outlined" />
                            </Divider>
                          )}
                        </Grid>
                      </Grid>
                    );
                  })}
                </Box>
              </FormGroup>
            </Card>
          )}

          <Grid item xs={12} style={{ paddingLeft: '10px' }}>
            {RangeShooter && RangeShooter.range_usage_status === "rejected" ? (
              <FileUpload
                title="Upload marksheet/certificate"
                required={true}
                onFileUpload={handleMarksheetUpload}
                allowedFormats={allowedFormats.ImageAndPDF}
                viewUploaded={marksheetURL}
              />
            ) : (
              <FileUpload
                title="Upload Self Attested copy of marksheet/certificate"
                required={true}
                onFileUpload={handleMarksheetUpload}
                allowedFormats={allowedFormats.ImageAndPDF}
                viewUploaded={marksheetURL}
              />
            )}
          </Grid>
        </Box>

        <Box my={2}>
          <Card sx={{ border: "1px solid #ccc", paddingTop: "30px" }}>
            <Box sx={{ mt: 2, backgroundColor: "grey.300" }} border={0.1} borderColor="grey.400" padding={1} marginLeft={1.5}>
              <Typography variant="h5" textAlign="center">
                Private And Confidential
              </Typography>
            </Box>
            <Grid container>
              <Grid container sx={{ padding: 1 }} spacing={3}>
                <Grid item xs={12} lg={6}>
                  <CustomFormLabel htmlFor="income">
                    {" "}
                    Monthly / Yearly Income <RequiredStar />
                  </CustomFormLabel>
                  <CustomTextField
                    id="income"
                    name="income"
                    placeholder="Enter Monthly / Yearly Income"
                    value={formData.confidentialDetails.income}
                    type="number"
                    onWheel={PreventWheelChange}
                    error={!!error.confidentialDetails?.income}
                    helperText={error.confidentialDetails?.income}
                    {...createFieldHandlers(0, "confidentialDetails")}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <CustomFormLabel htmlFor="businessDetails">
                    {" "}
                    Brief details of Business/Profession/Job <RequiredStar />
                  </CustomFormLabel>
                  <CustomTextField
                    id="businessDetails"
                    name="business_details"
                    placeholder="Enter details max 25 words"
                    value={formData.confidentialDetails.business_details}
                    error={!!error.confidentialDetails?.business_details}
                    helperText={error.confidentialDetails?.business_details}
                    {...createFieldHandlers(0, "confidentialDetails")}
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid item xs={5} sx={{ padding: 1 }}>
                <Card sx={{ marginTop: "10px", display: "flex", alignItems: "center", gap: "20px", py: 0.4 }}>
                  <Typography variant="subtitle2" marginBottom={0.5} sx={{}}>
                    Do you have Arm License? <b style={{ color: "red" }}>*</b>{" "}
                  </Typography>
                  <RadioGroup row aria-label="position" name="if_applied">
                    <FormControlLabel
                      name="if_applied"
                      value="yes"
                      checked={formData.confidentialDetails.if_applied === "yes"}
                      {...createFieldHandlers(0, "confidentialDetails")}
                      control={<CustomRadio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      name="if_applied"
                      value="no"
                      checked={formData.confidentialDetails.if_applied === "no"}
                      {...createFieldHandlers(0, "confidentialDetails")}
                      control={<CustomRadio />}
                      label="No"
                    />
                  </RadioGroup>
                </Card>
              </Grid>

              {formData.confidentialDetails.if_applied === "yes" && (
                <Grid container>
                  <Box sx={{ mt: 2 }} border={0.1} borderColor="grey.400" padding={1} marginLeft={2}>
                    <Typography variant="h5">Details of Licence</Typography>
                  </Box>

                  <Grid container sx={{ padding: 1 }} spacing={3}>
                    <Grid item xs={12} lg={4}>
                      <CustomFormLabel htmlFor="LicenseNo">
                        {" "}
                        License No. <RequiredStar />
                      </CustomFormLabel>
                      <CustomTextField
                        id="LicenseNo"
                        name="license_no"
                        placeholder="Enter License No"
                        value={formData.confidentialDetails.license_no}
                        error={!!error.confidentialDetails?.license_no}
                        helperText={error.confidentialDetails?.license_no}
                        {...createFieldHandlers(0, "confidentialDetails")}
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={12} lg={4}>
                      <CustomFormLabel htmlFor="IssuedBy">
                        {" "}
                        Issued By <RequiredStar />
                      </CustomFormLabel>
                      <CustomTextField
                        id="IssuedBy"
                        name="issues_by"
                        placeholder="Enter Issued By"
                        value={formData.confidentialDetails.issues_by}
                        error={!!error.confidentialDetails?.issues_by}
                        helperText={error.confidentialDetails?.issues_by}
                        {...createFieldHandlers(0, "confidentialDetails")}
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={12} lg={4}>
                      <CustomFormLabel htmlFor="ValidUpto">
                        {" "}
                        Valid upto <RequiredStar />
                      </CustomFormLabel>
                      <CustomTextField
                        id="ValidUpto"
                        name="valid_upto"
                        type="Date"
                        placeholder="Enter valid upto"
                        value={formData.confidentialDetails.valid_upto}
                        error={!!error.confidentialDetails?.valid_upto}
                        helperText={error.confidentialDetails?.valid_upto}
                        {...createFieldHandlers(0, "confidentialDetails")}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <CustomFormLabel sx={{ marginBottom: 0, marginLeft: 1 }} htmlFor="ValidUpto">
                    Firearm Details :
                  </CustomFormLabel>

                  <Card sx={{ border: "1px solid #ccc", mt: 2, paddingTop: "40px", marginLeft: 1, width: "98%" }}>
                    <FormGroup sx={{ position: "relative" }}>
                      <Box sx={{ background: "none", boxShadow: "none" }}>
                        <div style={{ display: "flex", width: "150px", alignItems: "center", position: "absolute", top: "-35px", right: "0px" }}>
                          <Button variant="outlined" sx={{ width: "20px", textAlign: "center" }} onClick={() => handleAddFields("confidentialDetails")}>
                            <AddIcon />
                          </Button>
                          {formData?.confidentialDetails.arm_type?.length > 1 && (
                            <Button variant="outlined" sx={{ width: "20px", textAlign: "center", ml: 0.5 }} onClick={() => handleRemoveFields("confidentialDetails")}>
                              <RemoveIcon />
                            </Button>
                          )}
                        </div>
                        {formData?.confidentialDetails.arm_type?.map((details: any, i: any) => {
                          return (
                            <Grid container spacing={2} mt={0.3} key={i}>
                              <Grid item xs={12} lg={4}>
                                <CustomTextField
                                  select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  name="type"
                                  value={details.type}
                                  {...createFieldHandlers(i, "confidentialDetails")}
                                  label="Firearm Type"
                                  fullWidth
                                  error={!!error.confidentialDetails.arm_type?.[i]?.type}
                                  helperText={error.confidentialDetails.arm_type?.[i]?.type ? 'Firearm Type is required.' : ''}
                                >
                                  <MenuItem value=""><i>None</i></MenuItem>
                                  <MenuItem value="Pistol">Pistol</MenuItem>
                                  <MenuItem value="Shotgun">Shotgun</MenuItem>
                                  <MenuItem value="Rifle">Rifle</MenuItem>
                                  <MenuItem value="Bigbore">Bigbore</MenuItem>
                                </CustomTextField>
                              </Grid>
                              <Grid item xs={4} lg={4}>
                                <FormControl fullWidth>
                                  <CustomTextField
                                    id={`serial_no-${i}`}
                                    label="Serial No"
                                    name={`serial_no`}
                                    value={details.serial_no}
                                    error={!!error.confidentialDetails.arm_type?.[i]?.serial_no}
                                    helperText={error.confidentialDetails.arm_type?.[i]?.serial_no}
                                    {...createFieldHandlers(i, "confidentialDetails")}
                                    fullWidth
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={4} lg={4}>
                                <FormControl fullWidth>
                                  <CustomTextField
                                    id={`caliber-${i}`}
                                    label="Caliber"
                                    name={`caliber`}
                                    value={details.caliber}
                                    error={!!error.confidentialDetails.arm_type?.[i]?.caliber}
                                    helperText={error.confidentialDetails.arm_type?.[i]?.caliber}
                                    {...createFieldHandlers(i, "confidentialDetails")}
                                    fullWidth
                                  />
                                </FormControl>
                              </Grid>
                            </Grid>
                          );
                        })}
                      </Box>
                    </FormGroup>
                  </Card>

                  <Grid item sx={{ paddingTop: 0, padding: 1 }} xs={12} md={4}>
                    {RangeShooter && RangeShooter.range_usage_status === "rejected" ? (
                      <FileUpload
                        title="Upload Arm license"
                        required={true}
                        viewUploaded={armLicenseURL}
                        onFileUpload={handleArmLicenseUpload}
                        allowedFormats={allowedFormats.ImageAndPDF}
                      />
                    ) : (
                      <FileUpload title=" Upload Arm license" required={true} onFileUpload={handleArmLicenseUpload} allowedFormats={allowedFormats.ImageAndPDF}
                        viewUploaded={armLicenseURL} />
                    )}
                    {/* <FormHelperText>"Profile picture is a required field"</FormHelperText> */}
                  </Grid>
                </Grid>
              )}
              <Grid container sx={{ padding: 1 }} spacing={3}>
                <Grid item xs={12} lg={6}>
                  <CustomFormLabel htmlFor="ExpUsingFirearms">
                    {" "}
                    Experience in using Firearms <RequiredStar />
                  </CustomFormLabel>
                  <CustomTextField
                    id="ExpUsingFirearms"
                    name="using_exp_firearms"
                    placeholder="Enter FireArm Experience"
                    value={formData.confidentialDetails.using_exp_firearms}
                    error={!!error.confidentialDetails?.using_exp_firearms}
                    helperText={error.confidentialDetails?.using_exp_firearms}
                    {...createFieldHandlers(0, "confidentialDetails")}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <CustomFormLabel htmlFor="CourseAttended">
                    {" "}
                    Details of Course Attended in Handling of Guns <RequiredStar />
                  </CustomFormLabel>
                  <CustomTextField
                    id="CourseAttended"
                    name="details_of_course_handling_guns"
                    placeholder="Enter Course Attended Details"
                    value={formData.confidentialDetails.details_of_course_handling_guns}
                    error={!!error.confidentialDetails?.details_of_course_handling_guns}
                    helperText={error.confidentialDetails?.details_of_course_handling_guns}
                    {...createFieldHandlers(0, "confidentialDetails")}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} sx={{ mt: -3 }} lg={6}>
                  <CustomFormLabel htmlFor="SportsActivity">
                    {" "}
                    Details of Sports Activity <RequiredStar />
                  </CustomFormLabel>
                  <CustomTextField
                    id="SportsActivity"
                    name="details_of_sports_activity"
                    placeholder="Enter Sports Activity Details"
                    value={formData.confidentialDetails.details_of_sports_activity}
                    error={!!error.confidentialDetails?.details_of_sports_activity}
                    helperText={error.confidentialDetails?.details_of_sports_activity}
                    {...createFieldHandlers(0, "confidentialDetails")}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} sx={{ mt: -3 }} lg={6}>
                  <CustomFormLabel htmlFor="OtherInfo">
                    {" "}
                    Other information <RequiredStar />
                  </CustomFormLabel>
                  <CustomTextField
                    id="OtherInfo"
                    name="other_informations"
                    placeholder="Enter Other info"
                    value={formData.confidentialDetails.other_informations}
                    error={!!error.confidentialDetails?.other_informations}
                    helperText={error.confidentialDetails?.other_informations}
                    {...createFieldHandlers(0, "confidentialDetails")}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Box>
      </Card>
    </>
  );
};

export default RangeRegistrationForm;
